.market-dashboard-light{
  background-color: white !important;
  color : black !important
}
.market-dashboard-dark{
  background-color: #000518 !important;
  /* color : black !important */
}
/* .p0{
  padding:0 !important;
}
.p0 div{
  padding:0  !important;
}
.col-p0{
  padding:0 3px 0 3px !important;
} */
.charting-container{
  padding: 0 !important
}
.no-gutters{
  padding: 0px !important
}
.asks{
  height: 350px!important;
  overflow-y: scroll;
}
.market .nav{
  padding-right: 0
}
.borderless div{
  border: none !important
}
a.active{
  color: #000;
  font-weight: 600 !important;
}

.market-tab .nav-link.active{
  color: #000;
  font-weight: 600 !important;
}
.nav-item .active{
  font-weight: 600 !important;
}
.market-tab a{
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.market-tab a:hover{
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.placeorder-nav small{
  margin-left: 20px !important;
  margin-right: 20px;
  cursor: pointer;
}
.placeorder-nav .active{
  margin-left: 20px !important;
  margin-right: 20px;
  cursor: pointer;
  font-weight: bold;
}
.placeorder-nav{
  height: 30px;
  padding: 0 10px !important;
  display: flex;
  justify-content: flex-start;
  direction: rtl;
  text-align: right;
  /* justify-content: space-around; */
  align-items: center;
  }
.bids{
  height: 350px!important;
  overflow-y: scroll;
}
.current{
  height: 40px;
  background-color: #fff;
  border-radius: 2px;
}
.current p{
  margin: 0!important;
  padding: 0 !important
}
.order-handler{
  border-top: 2px solid #000 !important
}
body.dark-mode{
  .market-tab a{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .market-tab a:hover{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .active{
    color: #fff !important;
    /* font-weight: bold; */
  }
  .current{
    height: 40px;
    background-color: #00000;
    border-radius: 2px;
  }
  .current p{
    margin: 0!important;
    padding: 0 !important
  }
}
