
.PERFECT-icon{
background: url('./crypto/pm.svg');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
border-radius: 50%;
width: 32px ;
height: 32px  ;
margin-top: 5px;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.perfect-icon{
  /* position: absolute; */
background: url('./crypto/pm.svg');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
border-radius: 50%;
width: 32px ;
height: 32px  ;
float: right ;
/* position: relative; */
display:block;
top: 7px;
content: inherit ; background-color : white; border-radius : 50%
}
.irr-icon{
background: url('./crypto/irr.svg');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
border-radius: 50%;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.usdt-icon{
background: url('./crypto/usdt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%;

}
.ftn-icon{
background: url('./crypto/ftn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
background-color: #000528;
outline: 2px solid #000528;
/* outline: solid 12px orange; */
outline-offset: -1px;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 80%
}
.btc-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wbtc-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.yfi-icon{
background: url('./crypto/yfi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.brl-icon{
background: url('./crypto/brl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tusd-icon{
background: url('./crypto/tusd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dai-icon{
background: url('./crypto/dai.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.paxg-icon{
background: url('./crypto/paxg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eth-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mkr-icon{
background: url('./crypto/mkr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bnb-icon{
background: url('./crypto/bnb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xmr-icon{
background: url('./crypto/xmr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}

.qnt-icon{
background: url('./crypto/qnt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bch-icon{
background: url('./crypto/bch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ltc-icon{
background: url('./crypto/ltc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aave-icon{
background: url('./crypto/aave.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ilv-icon{
background: url('./crypto/ilv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}



.dash-icon{
background: url('./crypto/dash.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.quick-icon{
background: url('./crypto/quick.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.time-icon{
background: url('./crypto/time.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.comp-icon{
background: url('./crypto/comp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zec-icon{
background: url('./crypto/zec.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.egld-icon{
background: url('./crypto/egld.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rpl-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xch-icon{
background: url('./crypto/xch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bchsv-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.metis-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ksm-icon{
background: url('./crypto/ksm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mln-icon{
background: url('./crypto/mln.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.urus-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.etc-icon{
background: url('./crypto/etc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sol-icon{
background: url('./crypto/sol.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nmr-icon{
background: url('./crypto/nmr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hapi-icon{
background: url('./crypto/hapi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.avax-icon{
background: url('./crypto/avax.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bch3l-icon{
background: url('./crypto/bch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.trb-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ens-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.op2s-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.atom-icon{
background: url('./crypto/atom.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.apt-icon{
background: url('./crypto/apt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zen-icon{
background: url('./crypto/zen.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ndau-icon{
background: url('./crypto/ndau.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cream-icon{
background: url('./crypto/cream.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ar-icon{
background: url('./crypto/ar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shib2s-icon{
background: url('./crypto/shib.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lyxe-icon{
background: url('./crypto/lyxe.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.grt2l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.apt2l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fxs-icon{
background: url('./crypto/fxs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.axs-icon{
background: url('./crypto/axs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.agix2s-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.plu-icon{
background: url('./crypto/plu.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.agix2l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lpt-icon{
background: url('./crypto/lpt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.neo-icon{
background: url('./crypto/neo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.movr-icon{
background: url('./crypto/movr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mana3l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.apt2s-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.grt2s-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kcs-icon{
background: url('./crypto/kcs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shib2l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gafi-icon{
background: url('./crypto/gafi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.orai-icon{
background: url('./crypto/orai.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eul-icon{
background: url('./crypto/eul.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.link-icon{
background: url('./crypto/link.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.uni-icon{
background: url('./crypto/uni.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bal-icon{
background: url('./crypto/bal.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.op2l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dot-icon{
background: url('./crypto/dot.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cvx-icon{
background: url('./crypto/cvx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.strong-icon{
background: url('./crypto/strong.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.unfi-icon{
background: url('./crypto/unfi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.icp-icon{
background: url('./crypto/icp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ape-icon{
background: url('./crypto/ape.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ht-icon{
background: url('./crypto/ht.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fil-icon{
background: url('./crypto/fil.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.prom-icon{
background: url('./crypto/prom.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ft-icon{
background: url('./crypto/ft.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dero-icon{
background: url('./crypto/dero.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bond-icon{
background: url('./crypto/bond.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cake-icon{
background: url('./crypto/cake.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ewt-icon{
background: url('./crypto/ewt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.unic-icon{
background: url('./crypto/unic.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.forth-icon{
background: url('./crypto/forth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pyr-icon{
background: url('./crypto/pyr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ethw-icon{
background: url('./crypto/ethw.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mask-icon{
background: url('./crypto/mask.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mtrg-icon{
background: url('./crypto/mtrg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.inj-icon{
background: url('./crypto/inj.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.route-icon{
background: url('./crypto/route.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.high-icon{
background: url('./crypto/high.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.trias-icon{
background: url('./crypto/trias.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gas-icon{
background: url('./crypto/gas.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sol3l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ant-icon{
background: url('./crypto/ant.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.alpine-icon{
background: url('./crypto/alpine.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dexe-icon{
background: url('./crypto/dexe.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dego-icon{
background: url('./crypto/dego.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dydx-icon{
background: url('./crypto/dydx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hnt-icon{
background: url('./crypto/hnt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ldo-icon{
background: url('./crypto/ldo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.op-icon{
background: url('./crypto/op.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.snx-icon{
background: url('./crypto/snx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.waves-icon{
background: url('./crypto/waves.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.near-icon{
background: url('./crypto/near.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ton-icon{
background: url('./crypto/ton.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rmrk-icon{
background: url('./crypto/rmrk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ern-icon{
background: url('./crypto/ern.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gal-icon{
background: url('./crypto/gal.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xrp3s-icon{
background: url('./crypto/xrp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rlc-icon{
background: url('./crypto/rlc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.uma-icon{
background: url('./crypto/uma.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mnw-icon{
background: url('./crypto/mnw.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hydra-icon{
background: url('./crypto/hydra.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.band-icon{
background: url('./crypto/band.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pdex-icon{
background: url('./crypto/pdex.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.luna-icon{
background: url('./crypto/luna.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gtc-icon{
background: url('./crypto/gtc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.alice-icon{
background: url('./crypto/alice.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ftt-icon{
background: url('./crypto/ftt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rune-icon{
background: url('./crypto/rune.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.erg-icon{
background: url('./crypto/erg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.api3-icon{
background: url('./crypto/api3.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.omg-icon{
background: url('./crypto/omg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.magic-icon{
background: url('./crypto/magic.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.twt-icon{
background: url('./crypto/twt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rndr-icon{
background: url('./crypto/rndr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.azero-icon{
background: url('./crypto/azero.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sushi-icon{
background: url('./crypto/sushi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.matic-icon{
background: url('./crypto/matic.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lit-icon{
background: url('./crypto/lit.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tribl-icon{
background: url('./crypto/tribl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xcad-icon{
background: url('./crypto/xcad.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ela-icon{
background: url('./crypto/ela.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vega-icon{
background: url('./crypto/vega.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dao-icon{
background: url('./crypto/dao.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wemix-icon{
background: url('./crypto/wemix.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kda-icon{
background: url('./crypto/kda.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.usdj-icon{
background: url('./crypto/usdj.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.imx-icon{
background: url('./crypto/imx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xtz-icon{
background: url('./crypto/xtz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.theta-icon{
background: url('./crypto/theta.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.flow-icon{
background: url('./crypto/flow.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eos-icon{
background: url('./crypto/eos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ampl-icon{
background: url('./crypto/ampl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sfund-icon{
background: url('./crypto/sfund.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.osmo-icon{
background: url('./crypto/osmo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mtl-icon{
background: url('./crypto/mtl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.usdc-icon{
background: url('./crypto/usdc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.busd-icon{
background: url('./crypto/busd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.usdp-icon{
background: url('./crypto/usdp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ousd-icon{
background: url('./crypto/ousd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cusd-icon{
background: url('./crypto/cusd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.usdd-icon{
background: url('./crypto/usdd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.crv-icon{
background: url('./crypto/crv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.orn-icon{
background: url('./crypto/orn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kava-icon{
background: url('./crypto/kava.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bch3s-icon{
background: url('./crypto/bch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xno-icon{
background: url('./crypto/xno.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ecox-icon{
background: url('./crypto/ecox.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.txa-icon{
background: url('./crypto/txa.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.whale-icon{
background: url('./crypto/whale.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.celo-icon{
background: url('./crypto/celo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.scrt-icon{
background: url('./crypto/scrt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vxv-icon{
background: url('./crypto/vxv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sdao-icon{
background: url('./crypto/sdao.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.knc-icon{
background: url('./crypto/knc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.flux-icon{
background: url('./crypto/flux.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.stg-icon{
background: url('./crypto/stg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.loc-icon{
background: url('./crypto/loc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cocos-icon{
background: url('./crypto/cocos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sand-icon{
background: url('./crypto/sand.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mana-icon{
background: url('./crypto/mana.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aury-icon{
background: url('./crypto/aury.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.burger-icon{
background: url('./crypto/burger.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.maha-icon{
background: url('./crypto/maha.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pmon-icon{
background: url('./crypto/pmon.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ava-icon{
background: url('./crypto/ava.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nif-icon{
background: url('./crypto/nif.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.perp-icon{
background: url('./crypto/perp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.loka-icon{
background: url('./crypto/loka.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xhv-icon{
background: url('./crypto/xhv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cws-icon{
background: url('./crypto/cws.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hibayc-icon{
background: url('./crypto/hibayc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ada3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.akt-icon{
background: url('./crypto/akt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dfi-icon{
background: url('./crypto/dfi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pcx-icon{
background: url('./crypto/pcx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ctc-icon{
background: url('./crypto/ctc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xprt-icon{
background: url('./crypto/xprt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hft-icon{
background: url('./crypto/hft.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.1inch-icon{
background: url('./crypto/1inch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wsienna-icon{
background: url('./crypto/wsienna.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sfp-icon{
background: url('./crypto/sfp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.agld-icon{
background: url('./crypto/agld.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xava-icon{
background: url('./crypto/xava.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pundix-icon{
background: url('./crypto/pundix.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ftm-icon{
background: url('./crypto/ftm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wild-icon{
background: url('./crypto/wild.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.btc3s-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.enj-icon{
background: url('./crypto/enj.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pols-icon{
background: url('./crypto/pols.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.glmr-icon{
background: url('./crypto/glmr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ocean-icon{
background: url('./crypto/ocean.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmt-icon{
background: url('./crypto/gmt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bnc-icon{
background: url('./crypto/bnc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.arrr-icon{
background: url('./crypto/arrr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fida-icon{
background: url('./crypto/fida.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lrc-icon{
background: url('./crypto/lrc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.trac-icon{
background: url('./crypto/trac.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.quartz-icon{
background: url('./crypto/quartz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.govi-icon{
background: url('./crypto/govi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mls-icon{
background: url('./crypto/mls.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.agix-icon{
background: url('./crypto/agix.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.storj-icon{
background: url('./crypto/storj.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dia-icon{
background: url('./crypto/dia.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xrp-icon{
background: url('./crypto/xrp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eos3l-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.GRAIL-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ada-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bico-icon{
background: url('./crypto/bico.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.jasmy3s-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.astra-icon{
background: url('./crypto/astra.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ovr-icon{
background: url('./crypto/ovr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.srm-icon{
background: url('./crypto/srm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fsn-icon{
background: url('./crypto/fsn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.voxel-icon{
background: url('./crypto/voxel.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nym-icon{
background: url('./crypto/nym.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hifidenza-icon{
background: url('./crypto/hifidenza.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aurora-icon{
background: url('./crypto/aurora.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.push-icon{
background: url('./crypto/push.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.krl-icon{
background: url('./crypto/krl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dot3s-icon{
background: url('./crypto/btc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sxp-icon{
background: url('./crypto/sxp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.looks-icon{
background: url('./crypto/looks.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mlk-icon{
background: url('./crypto/mlk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hipunks-icon{
background: url('./crypto/hipunks.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ygg-icon{
background: url('./crypto/ygg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.stx-icon{
background: url('./crypto/stx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lss-icon{
background: url('./crypto/lss.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dusk-icon{
background: url('./crypto/dusk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bnb3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.derc-icon{
background: url('./crypto/derc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gods-icon{
background: url('./crypto/gods.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.audio-icon{
background: url('./crypto/audio.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.naka-icon{
background: url('./crypto/naka.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kmd-icon{
background: url('./crypto/kmd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bat-icon{
background: url('./crypto/bat.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.modefi-icon{
background: url('./crypto/modefi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ltx-icon{
background: url('./crypto/ltx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.c98-icon{
background: url('./crypto/c98.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.uos-icon{
background: url('./crypto/uos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.algo-icon{
background: url('./crypto/algo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cfg-icon{
background: url('./crypto/cfg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dyp-icon{
background: url('./crypto/dyp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tribe-icon{
background: url('./crypto/tribe.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.boson-icon{
background: url('./crypto/boson.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.boba-icon{
background: url('./crypto/boba.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sclp-icon{
background: url('./crypto/sclp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.glm-icon{
background: url('./crypto/glm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nord-icon{
background: url('./crypto/nord.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.soul-icon{
background: url('./crypto/soul.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.front-icon{
background: url('./crypto/front.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.iota-icon{
background: url('./crypto/iota.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.woo-icon{
background: url('./crypto/woo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dar-icon{
background: url('./crypto/dar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.opul-icon{
background: url('./crypto/opul.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ausd-icon{
background: url('./crypto/ausd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ont-icon{
background: url('./crypto/ont.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sys-icon{
background: url('./crypto/sys.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiens3-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ngl-icon{
background: url('./crypto/ngl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bsw-icon{
background: url('./crypto/bsw.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cooha-icon{
background: url('./crypto/cooha.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.icx-icon{
background: url('./crypto/icx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.grin-icon{
background: url('./crypto/grin.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kar-icon{
background: url('./crypto/kar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eps-icon{
background: url('./crypto/eps.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bnb3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.marsh-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.neer-icon{
background: url('./crypto/neer.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ntvrk-icon{
background: url('./crypto/ntvrk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cov-icon{
background: url('./crypto/cov.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mv-icon{
background: url('./crypto/mv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ads-icon{
background: url('./crypto/ads.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.klay-icon{
background: url('./crypto/klay.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wmt-icon{
background: url('./crypto/wmt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hard-icon{
background: url('./crypto/hard.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vai-icon{
background: url('./crypto/vai.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.senso-icon{
background: url('./crypto/senso.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.chr-icon{
background: url('./crypto/chr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ata-icon{
background: url('./crypto/ata.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pha-icon{
background: url('./crypto/pha.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wrx-icon{
background: url('./crypto/wrx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.loki-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.prq-icon{
background: url('./crypto/prq.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.adx-icon{
background: url('./crypto/adx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pnt-icon{
background: url('./crypto/pnt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tlos-icon{
background: url('./crypto/tlos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tem-icon{
background: url('./crypto/tem.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.qrdo-icon{
background: url('./crypto/qrdo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.grt-icon{
background: url('./crypto/grt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ogn-icon{
background: url('./crypto/ogn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cirus-icon{
background: url('./crypto/cirus.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mir-icon{
background: url('./crypto/mir.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aca-icon{
background: url('./crypto/aca.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cqt-icon{
background: url('./crypto/cqt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lpool-icon{
background: url('./crypto/lpool.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ctsi-icon{
background: url('./crypto/ctsi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fear-icon{
background: url('./crypto/fear.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pstake-icon{
background: url('./crypto/pstake.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ape3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dodo-icon{
background: url('./crypto/dodo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bux-icon{
background: url('./crypto/bux.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fort-icon{
background: url('./crypto/fort.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.link3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ada3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.navi-icon{
background: url('./crypto/navi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.uni3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.chz-icon{
background: url('./crypto/chz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.p00ls-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.crpt-icon{
background: url('./crypto/crpt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.geeq-icon{
background: url('./crypto/geeq.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.indi-icon{
background: url('./crypto/indi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aergo-icon{
background: url('./crypto/aergo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vet3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.super-icon{
background: url('./crypto/super.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zcx-icon{
background: url('./crypto/zcx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.alpha-icon{
background: url('./crypto/alpha.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.h2o-icon{
background: url('./crypto/h2o.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xdefi-icon{
background: url('./crypto/xdefi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ftm3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cmp-icon{
background: url('./crypto/cmp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ceek-icon{
background: url('./crypto/ceek.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nkn-icon{
background: url('./crypto/nkn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.alt-icon{
background: url('./crypto/alt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xed-icon{
background: url('./crypto/xed.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.higazers-icon{
background: url('./crypto/higazers.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.himayc-icon{
background: url('./crypto/himayc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pbr-icon{
background: url('./crypto/pbr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.req-icon{
background: url('./crypto/req.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.orc-icon{
background: url('./crypto/orc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ioi-icon{
background: url('./crypto/ioi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eos3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mjt-icon{
background: url('./crypto/mjt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pkf-icon{
background: url('./crypto/pkf.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.oxt-icon{
background: url('./crypto/oxt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.trade-icon{
background: url('./crypto/trade.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ever-icon{
background: url('./crypto/ever.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lto-icon{
background: url('./crypto/lto.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.htr-icon{
background: url('./crypto/htr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.efi-icon{
background: url('./crypto/efi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hbar-icon{
background: url('./crypto/hbar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pokt-icon{
background: url('./crypto/pokt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hbb-icon{
background: url('./crypto/hbb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kok-icon{
background: url('./crypto/kok.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.oas-icon{
background: url('./crypto/oas.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.posi-icon{
background: url('./crypto/posi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.coti-icon{
background: url('./crypto/coti.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xlm-icon{
background: url('./crypto/xlm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ren-icon{
background: url('./crypto/ren.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.yld-icon{
background: url('./crypto/yld.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.matter-icon{
background: url('./crypto/matter.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.doge-icon{
background: url('./crypto/doge.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ggg-icon{
background: url('./crypto/ggg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mc-icon{
background: url('./crypto/mc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rose-icon{
background: url('./crypto/rose.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cgg-icon{
background: url('./crypto/cgg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.layer-icon{
background: url('./crypto/layer.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.astr-icon{
background: url('./crypto/astr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.abbc-icon{
background: url('./crypto/abbc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cro-icon{
background: url('./crypto/cro.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ngm-icon{
background: url('./crypto/ngm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wax-icon{
background: url('./crypto/wax.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bfc-icon{
background: url('./crypto/bfc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.suku-icon{
background: url('./crypto/suku.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.simp-icon{
background: url('./crypto/simp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.uno-icon{
background: url('./crypto/uno.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.clv-icon{
background: url('./crypto/clv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aleph-icon{
background: url('./crypto/aleph.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.titan-icon{
background: url('./crypto/titan.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.glch-icon{
background: url('./crypto/glch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mplx-icon{
background: url('./crypto/mplx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.noia-icon{
background: url('./crypto/noia.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.peel-icon{
background: url('./crypto/peel.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xcur-icon{
background: url('./crypto/xcur.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.trx-icon{
background: url('./crypto/trx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.polk-icon{
background: url('./crypto/polk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dpet-icon{
background: url('./crypto/dpet.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gari-icon{
background: url('./crypto/gari.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mana3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nwc-icon{
background: url('./crypto/nwc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pre-icon{
background: url('./crypto/pre.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dfa-icon{
background: url('./crypto/dfa.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cfx-icon{
background: url('./crypto/cfx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dag-icon{
background: url('./crypto/dag.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.skl-icon{
background: url('./crypto/skl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.jasmy3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xeta-icon{
background: url('./crypto/xeta.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cpool-icon{
background: url('./crypto/cpool.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tfuel-icon{
background: url('./crypto/tfuel.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.king-icon{
background: url('./crypto/king.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.man-icon{
background: url('./crypto/man.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.asd-icon{
background: url('./crypto/asd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tvk-icon{
background: url('./crypto/tvk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.avax3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.arnm-icon{
background: url('./crypto/arnm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.exrd-icon{
background: url('./crypto/exrd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.slim-icon{
background: url('./crypto/slim.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.swp-icon{
background: url('./crypto/swp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.anc-icon{
background: url('./crypto/anc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pias-icon{
background: url('./crypto/pias.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.galax-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.flame-icon{
background: url('./crypto/flame.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cti-icon{
background: url('./crypto/cti.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.squad-icon{
background: url('./crypto/squad.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vidt-icon{
background: url('./crypto/vidt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sushi3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tru-icon{
background: url('./crypto/tru.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xym-icon{
background: url('./crypto/xym.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ole-icon{
background: url('./crypto/ole.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dmtr-icon{
background: url('./crypto/dmtr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aioz-icon{
background: url('./crypto/aioz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.frm-icon{
background: url('./crypto/frm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.num-icon{
background: url('./crypto/num.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cix100-icon{
background: url('./crypto/cix100.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.arpa-icon{
background: url('./crypto/arpa.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.om-icon{
background: url('./crypto/om.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.flr-icon{
background: url('./crypto/flr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.trvl-icon{
background: url('./crypto/trvl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.t-icon{
background: url('./crypto/t.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.data-icon{
background: url('./crypto/data.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ngc-icon{
background: url('./crypto/ngc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cspr-icon{
background: url('./crypto/cspr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nhct-icon{
background: url('./crypto/nhct.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sushi3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xem-icon{
background: url('./crypto/xem.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hisand33-icon{
background: url('./crypto/hisand33.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.moni-icon{
background: url('./crypto/moni.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.boa-icon{
background: url('./crypto/boa.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bdx-icon{
background: url('./crypto/bdx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.slcl-icon{
background: url('./crypto/slcl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.orbs-icon{
background: url('./crypto/orbs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dfyn-icon{
background: url('./crypto/dfyn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.revu-icon{
background: url('./crypto/revu.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.srbp-icon{
background: url('./crypto/srbp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.solr-icon{
background: url('./crypto/solr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.starly-icon{
background: url('./crypto/starly.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hegic-icon{
background: url('./crypto/hegic.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xtag-icon{
background: url('./crypto/xtag.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.solve-icon{
background: url('./crypto/solve.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eqz-icon{
background: url('./crypto/eqz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.polc-icon{
background: url('./crypto/polc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hyve-icon{
background: url('./crypto/hyve.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmt3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hibirds-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ankr-icon{
background: url('./crypto/ankr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.iotx-icon{
background: url('./crypto/iotx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hisquiggle-icon{
background: url('./crypto/hisquiggle.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.near3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zee-icon{
background: url('./crypto/zee.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rvn-icon{
background: url('./crypto/rvn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dg-icon{
background: url('./crypto/dg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pld-icon{
background: url('./crypto/pld.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.galax3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mxc-icon{
background: url('./crypto/mxc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.people-icon{
background: url('./crypto/people.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wom-icon{
background: url('./crypto/wom.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zil-icon{
background: url('./crypto/zil.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kono-icon{
background: url('./crypto/kono.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiazuki-icon{
background: url('./crypto/hiazuki.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.jst-icon{
background: url('./crypto/jst.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ustc-icon{
background: url('./crypto/ustc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xdc-icon{
background: url('./crypto/xdc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hai-icon{
background: url('./crypto/hai.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sol3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mem-icon{
background: url('./crypto/mem.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ooe-icon{
background: url('./crypto/ooe.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.west-icon{
background: url('./crypto/west.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bbc-icon{
background: url('./crypto/bbc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vlx-icon{
background: url('./crypto/vlx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.one-icon{
background: url('./crypto/one.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fkx-icon{
background: url('./crypto/fkx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fitfi-icon{
background: url('./crypto/fitfi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.woop-icon{
background: url('./crypto/woop.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ape3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vet-icon{
background: url('./crypto/vet.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.beat-icon{
background: url('./crypto/beat.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.swash-icon{
background: url('./crypto/swash.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lavax-icon{
background: url('./crypto/lavax.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rosn-icon{
background: url('./crypto/rosn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vid-icon{
background: url('./crypto/vid.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mitx-icon{
background: url('./crypto/mitx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.acq-icon{
background: url('./crypto/acq.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ltc3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.stnd-icon{
background: url('./crypto/stnd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.acoin-icon{
background: url('./crypto/acoin.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.celr-icon{
background: url('./crypto/celr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pumlx-icon{
background: url('./crypto/pumlx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.glq-icon{
background: url('./crypto/glq.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tlm-icon{
background: url('./crypto/tlm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xtm-icon{
background: url('./crypto/xtm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hirenga-icon{
background: url('./crypto/hirenga.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xnl-icon{
background: url('./crypto/xnl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.haka-icon{
background: url('./crypto/haka.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.epk-icon{
background: url('./crypto/epk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ixs-icon{
background: url('./crypto/ixs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.store-icon{
background: url('./crypto/store.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.heart-icon{
background: url('./crypto/heart.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.racefi-icon{
background: url('./crypto/racefi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gst-icon{
background: url('./crypto/gst.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.amb-icon{
background: url('./crypto/amb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wal-icon{
background: url('./crypto/wal.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pol-icon{
background: url('./crypto/pol.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gem-icon{
background: url('./crypto/gem.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aog-icon{
background: url('./crypto/aog.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shill-icon{
background: url('./crypto/shill.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.reap-icon{
background: url('./crypto/reap.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.umb-icon{
background: url('./crypto/umb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.skey-icon{
background: url('./crypto/skey.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.locg-icon{
background: url('./crypto/locg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.oddz-icon{
background: url('./crypto/oddz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cwar-icon{
background: url('./crypto/cwar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tone-icon{
background: url('./crypto/tone.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cweb-icon{
background: url('./crypto/cweb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.efx-icon{
background: url('./crypto/efx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ach-icon{
background: url('./crypto/ach.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rfox-icon{
background: url('./crypto/rfox.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kdon-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.doge3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sku-icon{
background: url('./crypto/sku.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.revv-icon{
background: url('./crypto/revv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.upo-icon{
background: url('./crypto/upo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmee-icon{
background: url('./crypto/gmee.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.idea-icon{
background: url('./crypto/idea.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xcn-icon{
background: url('./crypto/xcn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zbc-icon{
background: url('./crypto/zbc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hivalhalla-icon{
background: url('./crypto/hivalhalla.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sweat-icon{
background: url('./crypto/sweat.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.primal-icon{
background: url('./crypto/primal.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.care-icon{
background: url('./crypto/care.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vr-icon{
background: url('./crypto/vr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hipenguins-icon{
background: url('./crypto/hipenguins.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ccd-icon{
background: url('./crypto/ccd.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kyl-icon{
background: url('./crypto/kyl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.divi-icon{
background: url('./crypto/divi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rly-icon{
background: url('./crypto/rly.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aave3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.club-icon{
background: url('./crypto/club.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.opct-icon{
background: url('./crypto/opct.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.epik-icon{
background: url('./crypto/epik.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.enq-icon{
background: url('./crypto/enq.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.qi-icon{
background: url('./crypto/qi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.phnx-icon{
background: url('./crypto/phnx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hidoodles-icon{
background: url('./crypto/hidoodles.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pond-icon{
background: url('./crypto/pond.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.iost-icon{
background: url('./crypto/iost.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vra-icon{
background: url('./crypto/vra.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.roar-icon{
background: url('./crypto/roar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dgb-icon{
background: url('./crypto/dgb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ghx-icon{
background: url('./crypto/ghx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eqx-icon{
background: url('./crypto/eqx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.map-icon{
background: url('./crypto/map.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.moov-icon{
background: url('./crypto/moov.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiclonex-icon{
background: url('./crypto/hiclonex.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pel-icon{
background: url('./crypto/pel.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ace-icon{
background: url('./crypto/ace.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cere-icon{
background: url('./crypto/cere.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.onston-icon{
background: url('./crypto/onston.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kat-icon{
background: url('./crypto/kat.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shft-icon{
background: url('./crypto/shft.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.melos-icon{
background: url('./crypto/melos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiodbs-icon{
background: url('./crypto/hiodbs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.afk-icon{
background: url('./crypto/afk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dpr-icon{
background: url('./crypto/dpr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hotcross-icon{
background: url('./crypto/hotcross.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kai-icon{
background: url('./crypto/kai.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sin-icon{
background: url('./crypto/sin.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mts-icon{
background: url('./crypto/mts.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lina-icon{
background: url('./crypto/lina.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.like-icon{
background: url('./crypto/like.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sdl-icon{
background: url('./crypto/sdl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bolt-icon{
background: url('./crypto/bolt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nrfb-icon{
background: url('./crypto/nrfb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ersdl-icon{
background: url('./crypto/ersdl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lmr-icon{
background: url('./crypto/lmr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bifi-icon{
background: url('./crypto/bifi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiens4-icon{
background: url('./crypto/hiens4.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.jasmy-icon{
background: url('./crypto/jasmy.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiundead-icon{
background: url('./crypto/hiundead.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ertha-icon{
background: url('./crypto/ertha.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cas-icon{
background: url('./crypto/cas.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hawk-icon{
background: url('./crypto/hawk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xyo-icon{
background: url('./crypto/xyo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rfuel-icon{
background: url('./crypto/rfuel.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vision-icon{
background: url('./crypto/vision.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.jar-icon{
background: url('./crypto/jar.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sun-icon{
background: url('./crypto/sun.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vet3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bondly-icon{
background: url('./crypto/bondly.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.synr-icon{
background: url('./crypto/synr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rev3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.prmx-icon{
background: url('./crypto/prmx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mnst-icon{
background: url('./crypto/mnst.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xcv-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hero-icon{
background: url('./crypto/hero.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hiod-icon{
background: url('./crypto/hiod.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.himeebits-icon{
background: url('./crypto/himeebits.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hifluf-icon{
background: url('./crypto/hifluf.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.matic3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dino-icon{
background: url('./crypto/dino.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mft-icon{
background: url('./crypto/mft.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.arx-icon{
background: url('./crypto/arx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.amp-icon{
background: url('./crypto/amp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.klv-icon{
background: url('./crypto/klv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ftg-icon{
background: url('./crypto/ftg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.maki-icon{
background: url('./crypto/maki.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmt3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tt-icon{
background: url('./crypto/tt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.spa-icon{
background: url('./crypto/spa.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ranker-icon{
background: url('./crypto/ranker.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wxt-icon{
background: url('./crypto/wxt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fra-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kara-icon{
background: url('./crypto/kara.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ogv-icon{
background: url('./crypto/ogv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.falcons-icon{
background: url('./crypto/falcons.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kol-icon{
background: url('./crypto/kol.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.clh-icon{
background: url('./crypto/clh.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gom2-icon{
background: url('./crypto/gom2.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cudos-icon{
background: url('./crypto/cudos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ckb-icon{
background: url('./crypto/ckb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bull-icon{
background: url('./crypto/bull.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.galax3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rsr-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.1earth-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.stepwatch-icon{
background: url('./crypto/stepwatch.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.well-icon{
background: url('./crypto/well.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.forestplus-icon{
background: url('./crypto/forestplus.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.hicoolcats-icon{
background: url('./crypto/hicoolcats.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.akro-icon{
background: url('./crypto/akro.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.blok-icon{
background: url('./crypto/blok.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lace-icon{
background: url('./crypto/lace.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.toko-icon{
background: url('./crypto/toko.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tower-icon{
background: url('./crypto/tower.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mbl-icon{
background: url('./crypto/mbl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.swingby-icon{
background: url('./crypto/swingby.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.reef-icon{
background: url('./crypto/reef.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.atom3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shr-icon{
background: url('./crypto/shr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.buy-icon{
background: url('./crypto/buy.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.love-icon{
background: url('./crypto/love.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kicks-icon{
background: url('./crypto/kicks.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.slp-icon{
background: url('./crypto/slp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.stc-icon{
background: url('./crypto/stc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xdb-icon{
background: url('./crypto/xdb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kma-icon{
background: url('./crypto/kma.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.jam-icon{
background: url('./crypto/jam.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.brwl-icon{
background: url('./crypto/brwl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ltc3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.etn-icon{
background: url('./crypto/etn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ila-icon{
background: url('./crypto/ila.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ai-icon{
background: url('./crypto/ai.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.himfers-icon{
background: url('./crypto/himfers.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dappx-icon{
background: url('./crypto/dappx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tel-icon{
background: url('./crypto/tel.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lym-icon{
background: url('./crypto/lym.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.velo-icon{
background: url('./crypto/velo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dreams-icon{
background: url('./crypto/dreams.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.h3ro3s-icon{
background: url('./crypto/h3ro3s.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.veed-icon{
background: url('./crypto/veed.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pbx-icon{
background: url('./crypto/pbx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.celt-icon{
background: url('./crypto/celt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.2crz-icon{
background: url('./crypto/2crz.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.wombat-icon{
background: url('./crypto/wombat.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sylo-icon{
background: url('./crypto/sylo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.form-icon{
background: url('./crypto/form.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eosc-icon{
background: url('./crypto/eosc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.btc3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xpr-icon{
background: url('./crypto/xpr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fly-icon{
background: url('./crypto/fly.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sand3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nim-icon{
background: url('./crypto/nim.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.card-icon{
background: url('./crypto/card.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.red-icon{
background: url('./crypto/red.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dc-icon{
background: url('./crypto/dc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xwg-icon{
background: url('./crypto/xwg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.isp-icon{
background: url('./crypto/isp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.credi-icon{
background: url('./crypto/credi.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dsla-icon{
background: url('./crypto/dsla.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tara-icon{
background: url('./crypto/tara.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rbp-icon{
background: url('./crypto/rbp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.vsys-icon{
background: url('./crypto/vsys.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mtv-icon{
background: url('./crypto/mtv.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.basic-icon{
background: url('./crypto/basic.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eth3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bepro-icon{
background: url('./crypto/bepro.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.swftc-icon{
background: url('./crypto/swftc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.act-icon{
background: url('./crypto/act.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.unb-icon{
background: url('./crypto/unb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.arker-icon{
background: url('./crypto/arker.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.carr-icon{
background: url('./crypto/carr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.suter-icon{
background: url('./crypto/suter.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mnet-icon{
background: url('./crypto/mnet.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gens-icon{
background: url('./crypto/gens.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lith-icon{
background: url('./crypto/lith.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xrp3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.pix-icon{
background: url('./crypto/pix.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mars4-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.axs3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.labs-icon{
background: url('./crypto/labs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dvpn-icon{
background: url('./crypto/dvpn.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.edg-icon{
background: url('./crypto/edg.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shx-icon{
background: url('./crypto/shx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ply-icon{
background: url('./crypto/ply.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dappt-icon{
background: url('./crypto/dappt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sha-icon{
background: url('./crypto/sha.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.frr-icon{
background: url('./crypto/frr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lbp-icon{
background: url('./crypto/lbp.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.psl-icon{
background: url('./crypto/psl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.r-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.eth3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmx-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bnx-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.link3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.plgr-icon{
background: url('./crypto/plgr.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.doge3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmm-icon{
background: url('./crypto/gmm.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.taum-icon{
background: url('./crypto/taum.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.dot3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bns-icon{
background: url('./crypto/bns.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.atom3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.apl-icon{
background: url('./crypto/apl.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.mswap-icon{
background: url('./crypto/mswap.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tidal-icon{
background: url('./crypto/tidal.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.epx-icon{
background: url('./crypto/epx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.son-icon{
background: url('./crypto/son.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.uni3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.gmb-icon{
background: url('./crypto/gmb.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.matic3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.chmb-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.srk-icon{
background: url('./crypto/srk.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aoa-icon{
background: url('./crypto/aoa.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ftm3s-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.raca-icon{
background: url('./crypto/raca.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.tky-icon{
background: url('./crypto/tky.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.bax-icon{
background: url('./crypto/bax.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.lunc-icon{
background: url('./crypto/lunc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sand3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.avax3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.aave3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.axs3l-icon{
background: url('./crypto/eth.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.win-icon{
background: url('./crypto/win.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.fcon-icon{
background: url('./crypto/fcon.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.polx-icon{
background: url('./crypto/polx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.egame-icon{
background: url('./crypto/egame.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ihc-icon{
background: url('./crypto/ihc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ubx-icon{
background: url('./crypto/ubx.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.near3l-icon{
background: url('./crypto/ada.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.xec-icon{
background: url('./crypto/xec.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.shib-icon{
background: url('./crypto/shib.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cult-icon{
background: url('./crypto/cult.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.ufo-icon{
background: url('./crypto/ufo.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.btt-icon{
background: url('./crypto/btt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.nft-icon{
background: url('./crypto/nft.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.brise-icon{
background: url('./crypto/brise.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.elon-icon{
background: url('./crypto/elon.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.sos-icon{
background: url('./crypto/sos.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.zkt-icon{
background: url('./crypto/zkt.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.rbs-icon{
background: url('./crypto/rbs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.srbs-icon{
background: url('./crypto/srbs.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.kin-icon{
background: url('./crypto/kin.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.cbc-icon{
background: url('./crypto/cbc.png');
background-repeat: no-repeat no-repeat ;
background-position: center ;
background-size: cover ;
width: 32px ;
height: 32px  ;
float: right ;
content: inherit ; background-color : white; border-radius : 50%
}
.GRAIL-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.TAO-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.yfdai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tiaup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ordi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ssv-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.auction-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.btcup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.injup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tia-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dcr-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ethup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.opdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lsk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ray-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.klub-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1cat-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.WLDDOWN-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gns-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.seidown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ordiup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.strike-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kace-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.updown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.uqc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lyx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.opup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cyber-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gnc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.flip-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vraup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.seiup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.orca-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.seam-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bakeup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dydxup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pendle-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.manta-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pythup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kasdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wld-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gtai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.infra-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arb3l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kasup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sols-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arb-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.jto-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.iron-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sui-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ethdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lqty-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zeta-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.neon-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.btcdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mina-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tomi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.luncup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ntrn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wldup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pyusd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.waxl-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arty-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.aa-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.fire-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.strax-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.unfidown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.syn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ator-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.combo-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mav-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.edu-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sui3l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.unfiup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sei-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mnt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.blur-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.trbdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.elf-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arkm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hifi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.fet-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.jup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vradown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.nfp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cfx2l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.id-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dmail-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pyth-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lbr-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.work-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1inch-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lmwr-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.oofp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.defi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pythdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kalt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zrx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bigtime-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.blz-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rdnt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.alex-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dovi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pzp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.finc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ofn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vrtx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ondo-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mnde-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.nom-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.shrap-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wif-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.trbup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bmx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cgpt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dydxdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.insp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.suip-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.polyx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bakedown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.suia-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cetus-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.aipad-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.nxra-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kas-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mubi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.atem-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hmnd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.id3l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pmg-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xpll-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tune-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.islm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cfx2s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tenet-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.loom-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.afg-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.port3-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.utk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.root-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pip-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tsugt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mxm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.csix-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.otk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.irl-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.goal-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.myro-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vanry-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kagi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dck-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arb3s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.silly-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sui3s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xrd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bidp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.id3s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sns-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ego-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.token-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepedown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.app-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.igu-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.grape-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.amu-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.locus-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.meme-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.halo-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hon-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rpk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.luncdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.candy-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gft-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.scpt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wldown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.turt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.izi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.biis-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.qkc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zkf-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.obi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gtt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.myria-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepeup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hibakc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tiadown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zpay-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kpol-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.karate-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.saros-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.klup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sidus-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1cat-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.aiepk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wlkn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zooa-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.turbos-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.roup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rndrdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.olt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.acs-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mobile-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mind-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.injdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vcore-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hiseals-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1earth-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mmm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dent-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hibeanz-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ordidown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hifriends-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zelix-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.verse-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.issp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rats-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.analos-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wen-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.etgm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.floki-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bob-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bonk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rfd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.coq-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepe-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sats-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xen-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.volt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ladys-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepe2-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.babydoge-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
