
@import './1200.css';
@import './575.css';

@import  './market.css';
@import  './crypto2.css';
@import  './sweetalert.css';
@import  './add.css';
/* @import 'crypto-currency-icon/dist/crypto-currency-icon.css' */
--dark-bg : #000518 ;
--dark-by : # ;
html{


  font-family: 'Samim', sans-serif !important;
}
.ag-icon{
  font-family: 'agGridQuartz' !important;
  }
.toast > .container > .message {
    padding: 14px 3px 8px 3px !important;

}
.text-white-fixed{
  color: #fff !important
}
.d-grid .caretup{
  margin-top: 0;
}
.usdt-btn{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.d-grid .caretdown{
  margin-top: -8px !important;
  /* margin-right: -2px */
}
.d-grid{
  display: grid !important
}

body p,a,span,h1,h2,h3,h4,h5,h6,li,tr,td,table,form{

  font-family: 'Samim', sans-serif !important;
}
body .btn{

  font-family: 'Samim', sans-serif !important;
}
/* #minichart:first-child{
  display: none !important;
  height: 0 !important;
  width: 0 !important
} */
.text-white{
  font-family: 'Samim', sans-serif !important;

}
.text-dark{
  font-family: 'Samim', sans-serif !important;

}
.text-light{
  font-family: 'Samim', sans-serif !important;

}
.text-mute{
  font-family: 'Samim', sans-serif !important;

}
.dropdown-toggle::after {
    display: block !important;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute  !important;
    width: 3px !important;
    right: 1px !important;
    top: 25px !important;
}
.sidebar p{
  color: #fff
}
.perfectmoney-logo{
  width: calc(75%);
  /* height: calc(75%); */

}
.custom-overflow{
  overflow: auto;
}

.flex{
  display: flex;
}
.dropdown-menu{
  max-height: 300px;
  overflow: auto;
  overflow-y: scroll;
}
input[type=range]::-ms-track { color: red; }
.info-col{
  border-bottom-left-radius: 10px
}
.d-box-f{
  background-color: #fff;
  color: #000;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  height: 80px
}
.d-box-f .usericon{
  margin: 5px;
  width: 65px;
  height: 65px;

}
.input-group-text{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important
}
.fee-section{
  padding: 20px;
  margin: 30px;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
}
.mt-10{
  margin-top: 100px
}
.toast-notification{
  position: fixed;
  top: 100px;
  right: 50px
}
.toast-header{
  border-bottom: 1px solid #fafafa;
}
.swal2-container{
  background: rgb(0 0 0 / 58%) !important;
  backdrop-filter: blur(5px) !important;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.toast{
  min-width: 350px;

}
.button.btn-close{
  float: right;
font-size: 1.5rem;
font-weight: 700;
line-height: 1;
color: #000;
text-shadow: 0 1px 0 #fff;
opacity: .5;
}
.close-button{
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  opacity: .5;
  position: absolute;
  margin-right: auto;
  left: 25px;
  top: 15px;
}
 .required::before { content:"*";
color: red;
margin-left: 5px}
.pb-10{
  padding-bottom: 100px
}
.current{
  font-size: 18px
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    width: 22px;
    height: 8px;
}
.fav-icon-active{
  color: #0b1a2f
}
.carousel .control-dots .dot{
  border-radius: 10px !important
}
tr{
  max-height: 50px;

  padding: 10px 0 10px 0;
}
.xl-1-bottom{
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
  width: calc(100%);
  height: 100px;
  background-color: #ffc107;
}
.xl-1-bottom-1{
  clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);  width: calc(100%);
  height: 30px;
  background-color: #ffc107;
  top: 0px
}
.drop-down-menu.custome-dropdown-menu{
  height: max-content !important
}
.tr{
  display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 15;
}
.text-main{
  color: #007bff
}
.bg-rapitex{
  background: url('./images/rx.svg') !important;
  background-repeat: no-repeat no-repeat !important;
  margin: auto;
  width: 150px;
  height: 50px;
  display: block;
  /* animation:  */
}
/* .paxg-hold{
  background: url('./images/')
} */

.exchange-container{

}
.down-arrow {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  background-color: #007bff
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);

}
@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.down-arrow {
  animation: jumpInfinite 1.5s infinite;
}
.logo-container-dark{
  background-image: url('./images/logo-dark.png') !important;
  background-repeat: no-repeat no-repeat !important;
  margin: auto;
  width: 200px;
  height: 50px;
  background-size: contain;
  background-position: center;
  display: block;
}

.search input{
  box-shadow: none;
  outline: none;
  padding-left: 10px;
  color: #000 !important;
  background-color: inherit;
  /* border-top-right-radius : 10px !important */
}
.exchange-input-symbol{
  position: absolute;
  left: 12px;
  top: 16px;
  color: #000;
}

.modal{
  /* top:150px !important */
}
.text-smaller{
  font-size: 8px !important
}
.modal-header{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: 60px
}
.modal-header h6{
  padding-bottom: 0px !important
}
.modal-header .close {
  margin-top: -10px   !important;
  margin-bottom:  auto   !important;
  margin-left: -10px  !important;
  cursor: pointer;
  float: left !important
}
.search input:focus{
  box-shadow: none;
  outline: none;
  padding-left: 10px;
  color: #000;

}

.fav-market-icon{
  color: #a0a0a0;
}
#footer {
    background-color: #000;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
  }

#footer a {
    text-decoration: none;
    color: #fff !important;
    font-weight: 100;
  }

#footer a :hover {
    color: #fafafa;
    font-weight: 400;
  }
.scrollHider{
  align-items: center;
  vertical-align: middle;
}
.card-info-cr .price-c{
  position: fixed;
  /* top:-40px; */
  height: 33px;
  width: 100%;
  background-color: #fff;
  bottom: 10;
  left: 0;
  right:0;
  border-radius: 10px
}
#usdt-darks{
    border:1px solid #6059595e !important;
    border-radius: 5px

  }

  .logo-container {
    background: url('./images/logo.png');
    background-repeat: no-repeat no-repeat !important;

    display: block;
    vertical-align: middle;
    align-items: center;
  }
  .logo-box{
    margin: auto;
    width: 200px
  }

  .hasbg{
    background: url('./images/bg2.png');
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    height: 650px;
    width: 100%;
    background-position: center;
    padding: 0 !important;
    background-color: #000518;
        background-blend-mode: exclusion;
  }


  .security-card-logo{
    width: 80px;
    height: 80px;
    background: url('./images/securepay-light.svg');
    margin: auto;
  }
  .piggy-card-logo{
    width: 80px;
    height: 80px;
    background: url('./images/piggybank-light.svg');
    margin: auto;
  }

  .wallet-card-logo{
    width: 80px;
    height: 80px;
    background: url('./images/digital-wallet-light.svg');
    margin: auto;
  }



  .footer-logo-container{
    background-image: url('./images/logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 250px;
    height: 50px;
    margin: auto;
  }
  .h70{
    max-height: 60px !important
  }
  .charting-container{
    height: 420px
  }

.home-asks-container{
  height: 300px;
  overflow :hidden;
}

.card-info-paxg{
  bottom: 0;
  position: absolute;
  width: 250px;
  top: auto;
  height: 250px;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 20px
  /* right: 0 */
}
.dark-toggle-inner{
  margin-top: 5px !important;
  text-align: center;
  display: contents;
}
.card-info-cr .card-top-iconbar{
  position: absolute;
  right: 4
}

.card-info-cr{
  width: 250px ;
  top: auto;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px 0 40px 0;
  opacity: 1;
  height: 230px;

}
.home-left{
  margin-top: 200px
}
.card-info{
  bottom: 0;
  width: 250px;
  top: auto;
  background-color: #fafafa;
  height: 250px;
  border-radius: 10px;
  margin-bottom: 25px;
  /* right: 0 */
}

.card-info-2{
  bottom: 0;
  position: absolute;
  width: 250px;
  top: auto;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.card-info-3{
  position: absolute;
  width: 250px;
  top:-20px;
  left: 0;
  background-color:  #fafafa;
  height: 350px;
  border-radius: 10px;
}
.card-info-4{
  position: absolute;
  width: 250px;
  top:0;
  /* right: 10; */
  background-color:  #fafafa;
  height: 250px;
  border-radius: 10px;
}
.card-info-btc::after{
  content: '';
  position: absolute;
  display: block;
  background-size: contain;
  background-position: top;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.1;
  top: 0;
  right: 0
}
.bg-btc::after{
  content: 'BTC';
  font-size: 42px;
  text-align: center;
  padding: 70px 0 0 0;
  position: absolute;
  display: block;
  background-size: contain;
  background-position: top;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.1;
  top: 0;
  right: 0
}
.bg-eth::after{
  content: 'ETH';
  font-size: 42px;
  text-align: center;
  padding: 70px 0 0 0;
  position: absolute;
  display: block;
  background-size: contain;
  background-position: top;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.1;
  top: 0;
  right: 0
}
.bg-bnb::after{
  content: 'BNB';
  font-size: 42px;
  text-align: center;
  padding: 70px 0 0 0;
  position: absolute;
  display: block;
  background-size: contain;
  background-position: top;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.1;
  top: 0;
  right: 0
}
.card-info-paxg::before{
  content: 'PAXG';
  font-size: 42px;
  text-align: center;
  padding: 70px 0 0 0;
  content: '';
  position: absolute;
  display: block;
  background-size: cover;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.5;
  top: 0;
  right: 0
}
.exchange-section{
  border: 1px solid #c4c2c2;
  border-radius: 20px;
  margin: 50px 20px;
  padding: 50px 20px;
  box-shadow: 5px 5px 12px #fafafa50
}
.ticker .tradingview-widget-copyright{
  display: none !important
}
.ticker {
  margin-top: -50px
}
.xl-navbar{
  z-index: 1111
}
.ticker #tradingview_widget_wrapper::after{
  content: '';
  width: 100%;
  height: 30px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px
}
/* #tradingview_widget_wrapper{
  height: calc(100% -10px)
}
#tradingview_widget_wrapper:nth-child(1){
  display: none !important
}
#tradingview_widget_wrapper a{
  display: none !important;

} */

.bg-paxg::before{
  content: 'PAXG';
  font-size: 42px;
  text-align: center;
  padding: 70px 0 0 0;
  position: absolute;
  display: block;
  background-size: cover;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.1;
  top: 0;
  right: 0
}
.card-info::before{
  content: '';
  position: absolute;
  display: block;
  background: url('./images/eth.png');
  background-size: cover;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.1;
}
.card-info-2::before{
  content: '';
  position: absolute;
  display: block;
  background: url('./images/pt2.jpeg');
  background-size: cover;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.03;
  top :0;
  right: 0;
  border-radius: 10px;
}

.card-info-3::before{
  content: '';
  position: absolute;
  display: block;
  background: url('./images/pt3.jpeg');
  background-size: cover;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.02;
  top :0;
  right: 0;
  border-radius: 10px;
}
.card-info-4::before{
  content: '';
  position: absolute;
  display: block;
  background: url('./images/pt4.jpeg');
  background-size: cover;
  width: calc(100%);
  height: calc(100%);
  opacity: 0.05;
  top :0;
  right: 0;
  border-radius: 10px;

}
.btn-success{
  background-color: #15a800 !important;
  border: 1px solid #15a800 !important;
}
.btn-success:hover{
  background-color: #15a800 !important;
  border: 1px solid #15a800 !important;
}


.exit-box .sidebar-icon-inherit{
  color: #000 !important;
  margin-right: 20px
}
.exit-box{
  display: flex;
  background-color: #ffc107 !important;
  color: #0f0f00 !important;
  height: 55px;
  border-left:4px solid #000 !important
}
.orderlist-footer{
  height: 30px
}
.orderlist-header{
  height: 40px !important;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
}

.titr{
  font-family: Titr !important
}
.nazanin{
  font-family: Nazanin !important
}
.morvarid{
  font-family: Morvarid !important
}
.lotus{
  font-family: Lotus !important
}
.trafic{
  font-family: Traffic !important
}
.zar{
  font-family: Zar !important
}
.lh-lg{
  line-height: 24px
}
.chart-menu{
  position: absolute;
  display: inline-block;
  margin: 10px;
  z-index: 2;
  direction: inherit;
  width: 100%
}
.custom-input-button{
  border-top-right-radius: 5px;
  border-bottom-right-radius : 5px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius : 0px !important;

}
.chart-index{
  position: fixed;
  display: inline-block;
  margin: -15px;
  z-index: 2;
  direction: ltr;
  width: 100%;
  display: inline-block;
  align-items: stretch;
}

p{
  margin-bottom: 5px !important
}

.icon-small{
  width: 20px !important;
  height: 20px !important
}

.badg-dblue:hover{
  background-color: #146c94;
}
.color-tag{
  border-radius: 50%;
  border: 1px solid #ffffff30
}
.badg-dblue{
  display: flex;
  background-color: #19a7ce;
  color: #000 !important;
  border-radius: 20px;
  width: 140px;
  margin-top: 10px;
  padding: 5px;


}

.p-gradiant{
  font-size: 72px;
  /* background: -webkit-linear-gradient( #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); */
  background: -webkit-linear-gradient( indigo,purple,pink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-yellow{
  background-color: #fcd535 !important;
  border: 1px solid #fcd535 !important
}
.btn-yellow:hover{
  background-color: #fcd535 !important;
  border: 1px solid #fcd535 !important
}
.h-600{
  min-height: 600px;
  max-height: max-content
}
.swap-dropdown{
  width: calc(100%)
}
tr{
  margin-top: 3px !important;
  padding-top: 5px !important
}
.activetab{
    border-bottom-color: #00ff37;
    border-bottom: 1px solid #00ff37;
    color: #00ff37;
    padding: 0 20px 20px 20px;
    align-self: center;
}
.vertical{
  margin-top: 45%;

}

.border-right-success{
  border-right: 5px solid cyan;
}
.border-right-fail{
  border-right: 5px solid orange;
}
.tx-row .bg-success{
  background-color: #e2efe5 !important
}
.tx-row .bg-danger{
  background-color: #ffeff9 !important
}
.tx-row .row{
  margin-left: 0px !important;
  margin-right: 0px !important
}
.box-shadow{
  box-shadow:  1px 0px 73px 5px #29c1cf40 !important
}
.main-tx-modal{
  position: absolute;
  background-color: #00000070;
  top:0;
  right: 0
}
.bg-light{
background-color: #f8f8f8 !important !important;
}
.bg-white{
background-color: #f8f8f8 !important !important;
}

.rtl {
  direction: rtl !important;
  text-align: right !important;
  font-family: 'Samim', sans-serif !important;
}
.user-icon-navbar::after{
      content: '';
      position: fixed;
      width: 10px;
      height: 10px;
      background-color: #37be37;
    top: 38px;
    left: 32px;
      display: block;
      z-index: 2;
      border-radius: 50%;
      font-weight: 100!important
}
.serach-magnifier-light{
   position: relative;
    display: block;
    top: -37px;
    right: 7px;
    margin-left: auto;
    padding: 6px 5px 5px 8px;
    background-color: #446b91;
    border-radius: 20px;
    width: 80px;
}
.light-search input:focus{
  outline: none;
  box-shadow: none;
  border: 1px solid #f5f5f5
}
.light-search input{
  width: 100%;
    border: 1px solid #0f0f00 !important;
    margin: 0 10px 10px 10px;
    height: 40px;
    border-radius: 20px;
    background-color: #fafafa;
    padding-right: 14px;
    color: #000518;
    font-size: 20px;
}
.light-search{
  width: 98%;
  border-radius: 20px;

}
#asks-list{
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
#bids-list{
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.orderlist-header{
  padding: 5px;
}
#tradingview_2d7e4{
    /* margin-left: -15px; */
    left: 0 !important;
    height: 300px !important;
    /* width: 1000px; */
    overflow-x: auto;
    overflow-y: hidden;
  }
.logo-text{
  color: '#019ec8' !important;
}
.text-middle{
  margin-top: 50px;
  text-align: center;
}
.h-200{
  height: 200px
}
   .react-draggable, .cursor {
     cursor: move;
   }
   .no-cursor {
     cursor: auto;
   }
   .cursor-y {
     cursor: ns-resize;
   }
   .cursor-x {
     cursor: ew-resize;
   }

   .react-draggable strong {
     background: #ddd;
     border: 1px solid #999;
     border-radius: 3px;
     display: block;
     margin-bottom: 10px;
     padding: 3px 5px;
     text-align: center;
   }

   .box {
     background: #fff;
     border: 1px solid #999;
     border-radius: 3px;
     width: 180px;
     height: 180px;
     margin: 10px;
     padding: 10px;
     float: left;
     z-index: 99999 !important;
     position: fixed;
     margin-top:100px;

   }
   .no-pointer-events {
     pointer-events: none;
   }
   .hovered {
     background-color: gray;
   }


   /*
    * RemWrapper needs to take it's styles from this element,
    * and this element can't have an absolute position after it's kicked in.
    * AFAIK it's not possible to do this directly in the RemWrapper component.
    */
   .rem-position-fix {
     position: static !important;
   }
.table-dark{
  background-color: #16253b !important
}
.pt-10{
  padding-top: 100px
}
.large-icon{
width: 100px !important;
height: 100px !important;
background-position: center
}
.base-icon{
  position: absolute;
  left: 24px;
  /* z-index: 2; */
  /* margin-right: -25px; */
}
.quote-icon{
  position: absolute;
  z-index: 2;
  margin-right: auto;
  margin-top: -20px;
  margin-left: 75px;
  border-radius: 50%;
  border: 1px solid #00000050
}
.input-group button:focus{
  border: 1px solid #ced4da;
  outline: none !important;
  box-shadow: none !important
}



.dropdown-toggle::after{
  margin-left: 1.25em !important
}
.horizental-menu p{
  margin-bottom: auto !important;
  margin-top: auto !important
}
.b-small{
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  display: table-cell;
  /* height: 10px; */
  text-align: center;
  background-color: #f5f5f5;
  padding: 3px 5px 0px 5px;
  border: 1px solid #00051830;
  border-radius: 5px
}
.mt--2{
  margin-top: -53px
}
.market-input{
  padding: 6px 12px 6px 0;
}
.market-input-dark .serach-magnifier{
  top: 0;
  right: 0
}
.add{
  color: #a4de02;
    border-radius: 4px;
    margin-right: auto;
    padding: 10px;

}
.add:hover{
  color: #a4de02 !important;
}
.dark-dropdownitem:hover{
  color: #000518 !important
}
.market-input-dark input{
  width: 100%;
  padding-left:  20px;
  text-align: left;
  font-family: serif;
  border: 1px solid #a4a4a426 !important;

}
.market-input-dark input:focus{
    border: 1px solid #a4a4a490 !important;
  outline: 0;
  box-shadow: none;
}

.market-input-prefix-plus{
  cursor: pointer;
  position: absolute;
margin-top: -35px;
left: 4px;
padding-left: 3px;
padding-right: 3px;
background-color: #f5f5f5;
margin-right: auto;
width: 30px;
height: 17px;
text-align: center;
/* padding-bottom: 16px; */
display: block;

}
.market-input-prefix{
  cursor: pointer;
  position: absolute;
margin-top: -19px;
left: 4px;
padding-left: 3px;
padding-right: 3px;
margin-right: auto;
background-color: #f5f5f5;
/* padding: 5px 10px; */
width: 30px;
height: 17px;
text-align: center;
/* padding-bottom: 16px; */
display: block;

}


.orderlist-container{
  width: 100%;
  height: 100%;
  /* position:relative; */
  top: 0px;
  left: 0px;
  /* pointer-events: none;
  perspective-origin: bottom;
  scroll : 100% */


}


.custom-overflow::-webkit-scrollbar-track {
  /* border: 1px solid #000; */
  padding: 2px 0;
  background-color: #white;
}
.custom-overflow::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.bg-darkblue{
  background-color: #021430 !important
}
.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #17314a50;
  /* border: 1px solid #000; */
}
.crypto-serach input{
  outline: 0;
  box-shadow: none;
  direction: ltr;
  color: white;
  border: 1px solid #c4c2c2 ;
  width: 100%;
  float: left;
  height: 41px;
  font-size: 16px;
  margin: 10px 0px 10px 0px;
  padding-left: 15px;
  border-radius: 25px
}
.crypto-serach input:focus{
  background-color: #fafafa;
  outline: 0;
  box-shadow: none;
  color: black
}
p,span,h1,h2,h3,h4,h5,h6{
  font-family: vazirmatn
}
.footer-links{
  display: grid;
}
.radius-sm:hover{
  border-radius: 3px
}
.radius-sm{
  border-radius: 5px;
  padding: 5px;
}

.row{
  margin-right:  0px !important;
 margin-left:  0px !important;
}
#earth {
  width: 300px;
  height: 300px;
  background: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTcMnCtXjURtbfBs5HE21XBvikautabBAhRyyOIaPbVhQTEoMBwjHoYaZqSYaPDXtQSIY&usqp=CAU);
  border-radius: 50%;
  background-size: 610px;
  box-shadow: inset 8px 36px 80px 36px rgb(0, 0, 0), inset -6px 0 12px 4px rgba(255, 255, 255, 0.3);
  animation-name: rotate;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotate;
     -webkit-animation-duration: 12s;
     -webkit-animation-iteration-count: infinite;
     -webkit-animation-timing-function: linear;
}
@keyframes rotate {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 610px 0px;
  }
}
@-webkit-keyframes rotate {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 610px 0px;
  }
}

.xl-1{
z-index: 100
}
.xl-3::after{
  content: '';
  position: absolute;
  background: url('./images/bgai.jpg');
  background-position: center;
  background-size: cover;
  width: calc(100%);
  top: 0;
  right: 0;
  opacity: 1;
  width: 100%;
  height: 100%
}
.xl-1::before{
  content: '';
  position: absolute;
  background: url('./images/xl-1.png');
  background-position: cover;
  background-size: cover;
  width: calc(100%);
  top: 0;
  right: 0;
  opacity: 0.03;
  width: 100%;
  height: 100%;
  z-index: -1
}
.w-xs{
  margin: 5px 10px 0 10px !important;
  padding: 10px !important
}

.footer-links{
  display: grid;
  padding-right: 10px;
  line-height: 40px !important
}
.bg-dark{
  background-color: #000518 !important
}
/* .btn-darkblue{
  border-bottom:  1px solid #021430 !important;
  background-color: #021430 !important;

  color: white;

} */
.light-search .input-span{
  position: absolute;


}
.market-list{
  padding: 0 5px 0 5px !important;

}
.small-icon{
  height: 16px ;
  width: 16px ;
  align-items: center;
  vertical-align: middle;
  /* margin-top: 9px */
}
.scrollHider-gutter{
  padding: 10px;
  margin: 5px 0
}
.badg-orange-signup{
  background-color: orange
}
.category-badge {
cursor: pointer;
padding: 3px 20px 0 20px !important;
background-color: #162b46;
margin: 0 5px 0 5px;
border-radius: 15px;
color: #fff
}
.active-category-badge {
padding: 3px 20px 0 20px !important;
background-color: #00bc8d;
border: 1px solid lightgray;
margin: 0 5px 0 5px;
border-radius: 15px;
color: #fff;
}
.market-table td{
  padding: 15px 10px 0 10px !important
}

.light-search input{
  border: 1px solid #fafafa;
  padding: 8px;
  /* font-size: 18px; */
}
.light-search input:focus-visible{
  border: 1px solid #fafafa;
  padding: 8px;
  /* font-size: 18px; */
}
.bg-ocean{
  background-color: #000518;
}
.authentication{
  /* background-color: #7487d1 !important */
}

.left-side-xs{
  display: none
}
.datepicker{
  /* font-family: TestFont, IRANSans, Tahoma, "Dejavu Sans", sans-serif;
  text-align: left;
  direction: ltr;
  width: 100%;
  color: black !important;
  background-color: #000 !important;
  background-image: none;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; */
}
.otp-input-style {
  width: 45px !important;
  margin-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  /* margin-right: 20px; */
  justify-content: space-between;
  direction: ltr;
  display: flex;


}
.steps_signup .active{
  background-color: #007bff;
  color: white
}
.steps_signup span{
  color: black;
  background-color: gray;
  padding: 16px 0 0 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: block;
    text-align: center;
    font-family: fantasy;
    /* font-weight: bold; */
    /* font-size: 14px; */
    z-index: 2;
    position: relative;
}
.steps_signup{
  color: black;
  padding: 30px 10px
}
.steps-step1::after{
  content: '';
  height: 90px;
  width: 2px;
  background-color: blue;
  display: block;
  position: absolute;
  margin-top: 13px;
  margin-right : 23px;
  z-index: 1;
}
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}


/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}
.custom-drop-down{
  border: 1px solid #856655;
  height: 60px;
  border-radius: 10px;
}
/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.no-pointer-events .custom-select:after {
    content: none;
}



.modal {
  padding: 0 !important;
}
.modal .modal-fullscreen{
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  overflow: hidden;
  top: 0;
  margin-top: 0px !important
}
.modal-fullscreen .modal-content{
  margin-top: 0px !important
}
.modal .modal-body {
  overflow-y: auto;
}

.text-tiny{
  font-size: 10px
}
.dropzone{
  border: 1px dotted black;
  padding: 10px 5px;
  margin-left: 3px;
  margin-right: 3px
}
.coin-list-light{
  border-radius: none !important;
}
.coin-list-light-header{
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #fafaff;
  padding: 20px 0 5px 0
}
.clear-filter{
  margin-top: -30px;
  padding-left: 10px
}

.dropdown-list-row{
  max-height: 280px;
  overflow-y: auto;
}
.dropdown-list-row::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #17314a;
}

.dropdown-list-row::-webkit-scrollbar {
  width: 10px;
}

.dropdown-list-row::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #17314a50;
  border: 1px solid #000;
}

.list-row:hover{
  background-color: #17314a50
}
.search-input input{
  font-family: vazirmatn;
  position: sticky !important;
  top:10px;
  padding: 5px 0px;
  width: calc(100%) !important;
  font-size: 14px !important;
  color: white !important;
  direction: ltr !important;
  text-align: left !important;
}
/* #exchange input{
 color : black !important
} */
.filter-input{
  direction: ltr;
}
.rotate-180{
  /* rotate : 180deg */
}
#transactions{
  padding: 50px 20px 100px 20px;
  border-radius: 25px;
  text-align: right;
  direction: rtl;
  background-color: white;
}
.withdrawal-info{
  line-height: 2rem
}

.f14{
  font-size: 14px !important
}
.f14 a{
  color: black !important;
  font-size: 14px;
  font-weight: 300
}
.btn-cyan{
  background-color: #e4e8ec;
  border: 1px solid #e4e8ec;
  color: #000518;
  padding: 10px 30px 10px 30px;

}
.user-UL{
  font-size: 16px;
    background-color: #58e2c7 !important;
    padding: 8px 10px;
    border-radius: 5px;
    color: black;
    font-weight: 600;
    font-family: sans-serif;
}
#hero-home-1 .hero-1-btn{
  margin-top: 100px;

}
.trx-list{
  /* height:25px; */
  padding: 20px 15px 0px 15px;
  border: 1px solid #fafafa;
  background-color: white;
  margin: 10px 0 10px 0;

}
.pagination{
  align-items: center

}
.pagination li{
  padding: 5px 10px;
  border: 1px solid #000;
  margin: 2px;
  cursor: pointer;
}

.exchange-option .modifier{
  transform: translate(0px, 30px) !important;
  /* background-color: #000518 !important; */
  color: #000518 !important
}
.exchange-option .btn-light{
  /* background-color: #ffffff00 !important; */
  /* color: white !important; */
  /* border: none !important; */
  text-align: center;
  width: calc(100%)
}
.clock span{
  color: black !important
}
.exchange-icon{
  background: url('./images/business.svg');
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  display: block;
  /* position :absolute; */
}
.exchange-option .dropdown{
  width: 100%;
  text-align: center;
  /* background-color: #ffffff00 */
}
.filterable-table-container .record-count{
  display: none !important
}
.filterable-table-container .pull-right{
  display: none !important
}
.filterable-table-container .filterable-table{
  margin-top: 20px;
}
.filterable-table-container .clear-filter{
  margin-right: 10px;
}
.filterable-table-container {
	.header-row {
		margin-top: 6px;

		& .exact-filters {
			margin-top: 20px;
		}

		& .record-count {
			display: none !important
		}
	}


	table.filterable-table thead tr th {
		white-space: nowrap;
		user-select: none;

		& span.fa {
			padding-left: 8px;
			color: #aaa;
		}
	}

	table.filterable-table tfoot tr td {
		font-weight: 700;
	}

	.filter-container {
		position: relative;

		& .filter-input, .page-size {
			width: 185px;
			display: inline;
		}
	}

	.clear-filter {
		position: absolute;
		right: 5px;
		top: 1px;
	}

	span.sortable, span.filterable {
		cursor: pointer;
	}

	span.filterable {
		border-bottom: 1px solid #91c1ff;
	}


	ul.pagination {
		& li a {
			user-select: none;
		}
		& li:not(.disabled) a {
			cursor: pointer;
		}
	}


	span.empty {
		font-style: italic;
		color: #999;
	}

	.filter-item {
	  border: 1px solid #ccc;
	  border-radius: 1px;
	  display: inline-block;
	  margin-right: 15px;

		& .filter-item-title {
		  background-color: #2f6e9b;
		  border-radius: 1px;
		  color: #fff;

			& .filter-item-remove {
			  color: #fff;
			  font-weight: 700;
			  user-select: none;
			  padding-right: 10px;
			  cursor: pointer;
			}
		}

		& .filter-item-title,
		& .filter-item-value {
		  padding: 5px 10px;
		  display: inline-block;
		}
	}

}
.header-row{

}
.exchange-option p{
  padding-top: 8px
}
.selected-coin-row .icon{
  margin-top: -3px
}
.table-icon{
  margin-top: -6px !important
}
.table-icon-base{
  margin-right: 22px !important;
  z-index: 2
}
.selected-coin-row{
  display: flex;
  justify-content: space-between;
}
.coin-list-row{

  padding: 0 5px 0 5px;

}
.absolute{
  position: absolute;
  /* margin-right: -5px; */
  margin-top: 1px
}
.exchange-option{
  background-color: #ffffff20;
  color: white;
  height: 40px;
  border-radius: 5px;
  /* text-align: right; */
  padding : 8px 5px 0 0;
  display: flex;
  justify-content: space-between;
}
.hero-1-btn::before{
  content: '';
  background: url('./images/link.svg');
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  position: absolute;
  margin-left: 100px;
  margin-top: 10px
}
.hero-1-btn{
  /* background-color: purple; */
  padding: 10px 30px 10px 30px;
  padding: 10px 30px 10px 30px;
  color: white;
  border-bottom: 2px solid white;
  min-height: 50px;
  font-size: 20px;
  width: 150px;
  font-family: Shabnam;
  text-align: left;
  /* text-decoration: underline; */
}
.table-responsive .table-borderless{
  border-color: inherit;
}
::marker {
    color: #139f00;
}
#hero-home-1{
  background: url('./images/home_hero2__1_.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px
}
#hero-home-3{
  padding: 100px 0 100px;
  background-color: black;
  background-image:  linear-gradient(to top ,#150024 3% ,black 50%);
  padding-bottom: 50px
}
.carousel-status{
  display: none;
}
.cr-list-button{
  border: 1px solid #fafafa20;
  border-radius: 20px !important;
  color : white;
  font-size: 12px;
  /* margin: 0 20px 0 20px; */
  background-color: #fafafa00 !important
}
.hero-home-2 {
  background-color: #061121;
  min-height: 200px;
  padding: 20px 20px 50px 20px;
}
.hero-3-image{
  margin-top: 100px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background: url('./images/home_hero_3.webp');
  ackground-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px
}
.hero-3-image-1{
  margin-top: 50px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background: url('./images/evm.webp');
  ackground-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 250px
}
.hero-3-image-2{
  margin-top: 50px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background: url('./images/ebm.webp');
  ackground-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 250px
}
.hero-3-text{
  background-color: #1a1a1a;
  min-height: 250px;
  color: #fafafa;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.tx-detail-section-left{
  border-bottom-left-radius: 10px
}
/* .tx-detail-section-left .p{
  margin-top: 20px
} */
.network-crypto {
  width: 120px;
height: 60px;

}
.network-doc{
  background-color: black;

}
.navbar-brand::before{
  content: '';
width: 0px;
margin-left: -12px;
height: 75px;
box-shadow: 10px 200px 300px 100px #00d7c3;
rotate: 45deg;
position: absolute;
}

.AuthLayer{
  background :url('./images/auth-bg01.jpg');
  background-size: cover;
}

  .main-auth  {
    /* background-color: #000518 !important */
  }
.auth-login-card{
  background-color: #ffffff20;
  padding: 25px 40px 40px 40px;
  border-radius: 50px
}
.AuthLayer .btn{
  border-radius: 25px
}


.activation{
  min-height: 500px !important
}

.bg-darkgreen{
background-color: #394c38
}
.bg-indigo{
background-color: indigo
}
#auth-login input:-webkit-autofill {
  color: #fff;
  background-color: none !important;
}
.swap-input input:-webkit-autofill {
  padding-left: 40px;
  background-color: #ffffff00 !important;
}
.swap .card-header{
  height: 47px;
}
.16pt{
  font-size: 16px !important
}
.bg-widget {
  background-color: #000518 !important;
}
.modifier{
  max-height: 300px;
  overflow-y: overlay;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: #fafafa;
  color: #000
}
.merchant-list{
  margin-left: 100px
}
.hero-icon-3::before{
  content: '';
  background: url('./images/zap-2.svg');
  width: 65px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: -66px;
  margin-top: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.radio-p{
  color: white !important;
  font-size: 1.5rem !important;
  font-weight: 300 !important
}
.radio-p::before{
  content: '';
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  margin-right: 10px;
  display: inline-grid;
  align-content: center;
  top: -7px;
    position: relative;

}
.radio-p-active{
  color: white !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important
}

.radio-p-active::before{
  content: '';
  width: 30px;
  height: 30px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  background-color: black;
  margin-right: 10px;
  display: inline-grid;
  align-content: center;
  top: -7px;
  position: relative;


}
.card-gradiant-blue{
  background-image: linear-gradient(300deg , #fafafa , #5656ff);
  background-color: #fafafa;

  border-radius: 25px;
  border: none;
  padding: 25px;
  box-shadow:  0 01px #ffffff00,
               01px 0 #ffffff00,
               01px 02px #ffffff00,
               02px 01px #ffffff00,
               02px 03px #ffffff00,
               03px 02px #ffffff00,
               5px 5px #e547e050,
               04px 03px #ffffff00,
               04px 05px #ffffff00,
               05px 04px #ffffff00,
               05px 06px #ffffff00,
               10px 10px #a7d6ff;
 display: flex;
 justify-content: space-between;
}
.has-gap{
  /* justify-content: space-between; */
  grid-gap: 10px

}
.card-gradiant-blue-d-block{
  /* background-image: linear-gradient(300deg , #e547e0bf , #5656ff); */
  background-color: #fafafa;
  border-radius: 25px;
  border: none;
  padding: 25px;
  box-shadow:  0 01px #ffffff00,
               01px 0 #ffffff00,
               01px 02px #ffffff00,
               02px 01px #ffffff00,
               02px 03px #ffffff00,
               03px 02px #ffffff00,
               5px 5px #e547e050,
               04px 03px #ffffff00,
               04px 05px #ffffff00,
               05px 04px #ffffff00,
               05px 06px #ffffff00,
               10px 10px #a7d6ff;
 justify-content: space-between;
}
.card-small{
  box-shadow: 0px 0px 18px 10px #ffffff25 !important
}

.text-subject{
  color: #253864;
    font-size: 45px;
}
.tradingview-widget-copyright {
  display: none !important
}
.js-copyright-label{
  display: none !important
}
.control-dots .selected{
  width: 20px;
  background: #000
}
.control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
    /* background-color: #000518 */
}
.radius{
  border-radius: 20px
}
.text-subject2{
  color: #253864;
    font-size: 30px;
}
.text-subject3{
  color: #253864;
    font-size: 20px;
}
.text-subject4{
  color: #253864;
    font-size: 14px;
}
.card .bordered{
  border-color:  #ffffff50 !important
}
.card-body {
  /* line-height: 3rem !important; */
}

.rpn-notification-holder{
  font-family: 'Shabnam';
  text-align:right !important
}
.dot-light{

  display: flex;
}
.dot-light div:nth-child(1){
  content: '';
display: flex;
width: 5px;
height: 5px;
border-radius: 50%;
background-color: #000;
margin-left: 10px;
animation: lds-grid 1s linear infinite;
margin-top: -24px;
animation-delay: 0s
}
.dot-light div:nth-child(2){
  content: '';
display: flex;
width: 4px;
height: 5px;
border-radius: 50%;
background-color: #000;
margin-left: 10px;
animation: lds-grid 1s linear infinite;
margin-top: -24px;
animation-delay: 0.4s
}
.dot-light div:nth-child(3){
  content: '';
display: flex;
width: 5px;
height: 5px;
border-radius: 50%;
background-color: #000;
margin-left: 10px;
animation: lds-grid 1s linear infinite;
margin-top: -24px;
animation-delay: 0.7s
}



.dark-shadow{
  text-shadow: 0px 0px 4px gray;
}
.light-shadow{
  text-shadow: 0px 0px 8px #fafafa;
}
.arial{
  font-family: 'arial'
}
.ltr{
  text-align: left;
  direction: ltr;

}
#supported-crypto .card{
  border: 1px solid #ffffff10 !important;
  background-color: #ffffff00;
  box-shadow: 0px 0px 20px 3px #ffffff50;
  border-radius: 5px
}
.header-bg-dark{
  display: none;
}
.min-menu{
  min-width: 50px !important;
  padding: 0.5rem !important;
  border: 1px solid #6059595e !important;
}
#installation .card{
  border: none !important;
  background-color: #ffffff00
}
.card-footer{
  /* background-color: #040d21 !important;
  margin-bottom : 0; */
  /* position : fixed */
}
.exchange-box{
    text-align: center;
    margin: auto;
    width: 70px;
    height: 70px;
    background-color: #e6e6e6;
    border: 1px solid #dedede;
    border-radius: 50%;
    padding: 10px 10px;
}
.xs-icon{
  width: 16px !important;
  height: 16px !important;
}
.card{
  border-radius: 10px !important
}
.logo-shadow::before{
  content: '';
width: 0px;
margin-left: -12px;
height: 75px;
box-shadow: -35px 0px 300px 100px #fafafa;
rotate: 45deg;
position: absolute;
}
.bg-shadow{
  margin-left: -55px;
  height: 75px;
  box-shadow:  1px 0px 73px 28px #00bccdbd;
}
.logo-shadow2::before{
  content: '';
width: 0px;
margin-left: -12px;
height: 75px;
box-shadow: 10px 200px 300px 100px #00d7c3;
rotate: 45deg;
position: absolute;
}
.card-bg-1 tr{
  margin: 20px 0px !important
}
.card-bg-1{
  background-image: linear-gradient(purple,indigo);
  background-size: cover;
  min-height: 250px;
  border : none;
  border-radius: 20px;
  padding: 20px;
  /* margin-right: 5px; */
  color : white
}
.mr-auto{
  margin-right: auto !important
}
.card-bg-2{
  background-image: linear-gradient(indigo,purple);
  background-size: cover;
  min-height: 250px;
  border : none;
  border-radius: 20px;
  padding: 20px;
  /* margin-right: 5px; */
  color : white
}
.card-protofilo{
  min-height: 250px;
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
  border: none;
  width: 100%;
  /* margin-left: 15px;
  margin-right: 15px */
}
.color-tag{
  height: 15px;
  width: 15px;
  display: flex;
  float: row
}


.d-box .box{
  background-color:  white !important;
  margin: 10px;
      height: 300px;
      width: 600px;
      box-shadow:  0 01px #fafafa,
                   01px 0 #fafafa,
                   01px 02px #fafafa,
                   02px 01px #fafafa,
                   02px 03px #fafafa,
                   03px 02px #fafafa,
                   03px 04px white,
                   04px 03px #fafafa,
                   04px 05px #fafafa,
                   05px 04px #fafafa,
                   05px 06px #fafafa,
                   30px 30px black;
 border-radius: 5px
}
.kucoin-icon-lg{
  margin: 10px;
  width: 35px;
  height: 35px;
  background: url('./crypto/kcs.svg');
  background-size: contain;
  background-repeat: no-repeat no-repeat;
}
.count:before {
  counter-increment: section;
  content: counter(section);
  font-weight: bold;
  font-size: 14px
}
.h-50{
  max-height: 50px !important
}
.nav-link{
  padding: 0 10px 0 10px !important
}
.navbar-dark .navbar-brand {
  color: #fff
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
  color: #fff
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, .5);
  border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
  color: #fff
}

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff
}
.sidebar{
  font-size: 14px !important;
  top: 0
}
.icon-container .sidebar-icon{
  margin-top: auto;
  margin-bottom: auto;
}
.sidebar-item-active p{
margin-top: 3px !important;
margin-right: 30px !important;
padding-bottom: 0 !important;
font-size: 16px

}
.sidebar-item p{
margin-top: 3px !important;
margin-right: 30px !important;
padding-bottom: 0 !important;
font-size: 16px

}
.logo-text{
  color: #019ec8 !important;
}
.logo-text:hover{
  color: #019ec8 !important;
}
.my-option *{
  justify-content: space-between;
  color : blue !important
}
#selecttaker , #selectticker{
  border: 1px solid #9999991f;
background-color: #e0f4ff1f;
padding: 8px 11px 8px 9px;
border-radius: 6px;
color: #999A9A;

}
.hr-vertical{
  transform: rotate(90deg);
  width: 50px
}
.KCS-icon-lg{
  margin: 10px;
  width: 35px;
  height: 35px;
  background: url('./crypto/kcs.svg');
  background-size: contain;
  background-repeat: no-repeat no-repeat;
}
