@media (min-width:768px) and (max-width: 1199px) {

  .row-row-1 .col-fit-left{
    height: 450px;
    padding: 0 10px 0 0 !important;
  }
  .row-row-1 .col-fit-right{
    height: 450px;
    padding: 0 0 0 10px !important;
  }
  .border-light{
    border-radius: 5px
  }
  .lh-xl{
    line-height: 38px
  }
  .coin-name{
    display: none
  }
}

@media (max-width : 1199.98px){
  .xs-navbar{
    padding-bottom: 20px !important
  }
  .tx-detal.modal.dialog{
    position: fixed;
  }
  .custom-input-button{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius : 5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius : 0px !important;

  }
  .text-success {
    color: green
  }
  .form-search{
    border-radius: 35px !important;
    font-size: 16px;

  }
  .market-modal .modal-content{
    margin: 0 !important;
    padding:  0 !important;
    border-radius: 0 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: calc(100% - 66px);
  }
  .modal-dialog.market-modal{
    margin-top: 0 !important
  }
  .modal-dialog .modal-content{
  top: 0 !important
  }
  .thumb-img{
    width: 100px;
    height: 100px;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px
  }
  .tx-detail-section-left{
    padding: 20px 0 0 50px !important
  }
  div:where(.swal2-container) div:where(.swal2-popup) {
    display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    width: 20em;
    height: 11em;
    max-width: 100%;
    padding: 0 0 5px 0;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;

}
div:where(.swal2-container) h2:where(.swal2-title) {
    position: relative;
    max-width: 100%;
    /* margin: 0; */
    padding: 2px;
    color: inherit;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}
.btn{
  height: 50px;
  border-radius: 5px
}
input{
  height: 50px;

}
      body{
        overflow-x: hidden;
        scroll-behavior: smooth !important;
        padding-top: 50px;
        font-family: 'Samim', sans-serif !important;
        padding: 50px 10px;
      }
      .input-group .btn{
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
      .btn-group>.btn:last-child {
        margin-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .btn-group>.btn:first-child {
        margin-right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .chart-container-pie{
        margin-top: 30px;
        margin-bottom: 20px
      }
      .d-row-1{
        margin: 30px 0;
      }
      .btn-light-outline{
        border: 1px solid #fafafa;
        border-radius: 5px;
        text-align: right;
        direction: rtl;
      }
      .recharts-wrapper{
        margin-left: auto;

      }
      .chart-hybrid{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .menuBox{
        height: 50px;
        width: auto;
        overflow-x: scroll;
      }
      .menuBox p{
        width: 100px;
        text-align: center;
      }
      #dashboard{
        padding-bottom: 100px
      }
      .icker-list-deposit{

      }
      .h-0{
        height: 0px !important;
        opacity: 0
      }
      .btn-primary{
        border: none;
      }
      .xs-hide{
        display: none !important
      }
      .list-header{
        display: none !important
      }
      .crypto-rows .btn{
        height: 36px !important
      }
      .dropdown .btn{
          height: 50px
      }
      .btn-primary{

      }
      .dropdown-menu.show{
        background-color: #fff;
        color: #000
      }
      .btn-small{
        height: 50px
      }
      .modal-menu{
        height: 100%;
        margin-top: 70px;
        padding-right: 30px;
        color:#fff;
        overflow-y: scroll;
      }
      .xs-left{
        margin-right: auto !important;
        margin-left: 0;
        margin-top: 10px
      }
      .tx-detail-section p{
        font-family: 'Samim' , ;

      }
      .modal .modal-body{
        padding: 10px !important
      }
      .modal .modal-content{
        top:56;
        position: fixed;
        overflow: hidden;

      }
    .active-tab{
          color: #58e2c7;
          font-size: 14px;
          font-weight: bold;
          color : #A4DE02 !important ;
          border-bottom: 2px solid #A4DE02;
          padding-bottom: 8px;
          padding-top: 3px;
          width: 100px;
          text-align: center;
        }
      .tx-detail-section small{
          line-height: 30px
      }
      .btn.w-50{
        width: 75%  !important
      }
      .exchange-box{
        background-color: inherit;
      }
      .tx-detail-section .w-25{
        width: 50% !important ;

      }
      .input-custome-button {
        background-color: inherit;
        color: inherit;
        border: 1px solid #fafafa40;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      .input-custome-button .slug{
        display: none
      }
      .input-custome-button .icon{
        width: 19px;
    height: 19px;
    margin-right: 10px;
    }
    .icon-row small{
      color: #e2e2e2;
      margin-top: 10px !important;
      font-size: 9px;
      display: block;
    }
      .bg-transparent{
        background-color: #84848408
      }
    body.light  .text-dark{
        color: #000
      }
    body.dark  .text-dark{
        color: #fff
      }
      .xs-mt-5{
        margin-top: 50px
      }
      .radius{
        border-radius: 10px !important
      }
      .xs-slider-200 .slide-title{
        margin-bottom: 50px;
        padding-top: 20px

      }
      .xs-slider-200 .slider-p{
        padding-right: 20px;
        margin-right: 20px
      }
      .xs-slider-200 .slider-p::before{
        content: '';
        width: 20px;
        height: 20px;
        background: url('./images/tickblue.svg');
        position: fixed;
        margin-top: -3px;
        margin-right: -25px;
        background-size: cover;
      }
      .xs-slider-200 .slider-wrapper{
        height: 230px !important;
        border-radius: 10px
      }
    .border-light{
      border: 1px solid #0005180a
    }
      .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
      }
      .menuSection p{
        color: #inherit;
      }
      .hue::before{
        content : none;

        background-color: inherit;
      }
      .menuSection
      {
        width: max-content;
        display:inline-block;
        overflow:hidden;
        margin: 0 20px 0 20px;
        align-items: baseline;
        margin-top: auto !important;
        margin-bottom: auto !important;
        font-size: 14px;
        padding-top: 10px
      }
      .chart-in{
        height: 350px
      }
      .main-logo-light{
          height: 35px;
      }
      .main-logo-dark{
        display: none
      }
      .sample-img{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
      }
      .list-coin-slug-flex{
        margin-inline: 36px;
        margin-top: 3px;
        display: block;
        width: max-content;
        position: absolute;
        font-size: 12px
      }
      .h-300{
        min-height: 300px;
      }
      #dashboard-chart-section{
        background-color: #fff;
        /* height: 300px;
        margin-top: 50px;
        padding: 10px */
      }
      .button-row .active-tabs{
        margin-top: 10px;
        max-width:100px;
        width: max-content;
        text-align:center;
      }
      .menuBox
      {
          overflow:scroll;
          white-space:nowrap;
          vertical-align: middle;
          align-items: center;

      }
      .scrollHider .menuBox{
        background-color: #000518;
        padding: 15px 30px 0 0;
      }

      .menuBox a{
        color: #fff;
        margin-left: 30px;
        display: inline-block;
      }
      .menuBox p{
        color: #fff;
        margin-left: 30px;
        display: inline-block;
      }
      a.active-tab{
        color: #58e2c7;
        font-size: 14px;
        font-weight: bold;
      }
      p.active-tab{
        color: #58e2c7;
        font-size: 14px;
        font-weight: bold;
      }
      .scrollHider
      {
      margin-left: -20px;
      margin-right: -20px;
      }
      .info-section{
        margin-top: 50px
      }
      .gradiant-icon span{
        background: -moz-linear-gradient(top, #e72c83 0%, #a742c6 100%);
        background: -webkit-linear-gradient(top, #e72c83 0%, #a742c6 100%);
        background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
      .fa-percent::before{
        font-size: 15px;
        background: -moz-linear-gradient(top, #e72c83 0%, #a742c6 100%);
        background: -webkit-linear-gradient(top, #e72c83 0%, #a742c6 100%);
        background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
      body.light .xs-modal-menu{
          background-color: #fff;
          border-bottom: 1px solid #00022290;
          position: fixed !important;
          top:55 !important;
          right: 0  !important;
          left: 0 ;
          width: 100%;
          height: 100%;
          left: 0;
          z-index: 14;
          padding: 20px 10px;
          min-height: 700px;
          overflow: auto;
          overflow-y: scroll;
        }

      body.light  .xs-modal-menu .sidebar-icon{
        color: #000 !important
      }
      body.light  .xs-modal-menu p,svg {
        color: #000 ;
        font-size: 10px
      }
      body.dark .xs-modal-menu .sidebar-icon{
        color: #fff !important
      }
      body.dark .xs-modal-menu svg {
        color: #fff !important;
        font-size: 10px
      }
      body.dark .xs-modal-menu p {
        color: #fff !important;
        font-size: 10px
      }

      .body.dark  .xs-modal-menu::-webkit-scrollbar {
          width: 0.2em;
      }

      .body.dark  .xs-modal-menu::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      }

      .body.dark  .xs-modal-menu::-webkit-scrollbar-thumb {
        background-color: #000518;
        outline: 1px solid #000518;
      }
      body.dark  .xs-modal-menu{
            position: fixed !important;
            top:55 !important;
            right: 0px;
            left: 0px;
            color: #fff !important;
            background-color: #000518;
            border-bottom: 1px solid #00022290;
            width: 100%;
            height: 100%;
            left: 0;
            z-index: 14;
            padding: 20px 10px;
            min-height: 700px;
            overflow: auto;
            overflow-y: scroll;
          }
      body.light a span p h1 h2 h3 h4 h5 h6 td tr{
        color: #000;
      }
      body.dark a span p h1 h2 h3 h4 h5 h6 td tr{
        color: #fff;
      }
      body.light{
        color: #000;
        .main-logo-light{
            height: 35px;
        }

        .btn-light-outline{
          border: 1px solid #fafafa;
        }

        .menuBox a{
            color: #fff ;
            margin-left: 30px;
            display: inline-block;
            text-align: center;
          }
        .main-logo-dark{
          display: none
        }
        .gradiant-icon .fa {
          display: inline;
          margin-left: 15px;
        }


      }
      html, body {
        overflow-x: hidden !important;
        width: auto!important;
        position: relative !important;
      }
      .scrollHider .menuBox-dashboard{
        height: 105px;
        background-color: #84848408;
        padding: 15px 30px 0 0;
        overflow:auto;
        white-space:nowrap;
        vertical-align: middle;
        align-items: center;
        overflow-y: hidden;

      }
      form input{
        padding: 10px 30px 10px 30px;
        min-height: 50px;
        border: 1px solid #0042514d;
        background-color: none;
        border-radius: 5px
      }
      .btn{
        padding: 10px 30px 10px 30px;
        /* min-height: 50px; */

      }
      .exchange-input-symbol-left{
        position: relative;
          float: left;
          left: 10px;
          top: -34px;
          color: #000;
          z-index: 13;
          padding-left: 5px
      }
      .modal-dialog{
        margin:50px auto !important;
        height: 100% !important;
        left: 0px !important;
        right: 0px !important
      }
      .main-modal .modal-content{
        height: 100%;
        bottom: 100px
      }
      .main-modal.modal-dialog{
        position: fixed;
        overflow: hidden;
        margin-top: 53px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        border-radius: 0px !important;
        left: 0px !important;
        right: 0px !important;
      }
      label{
        font-size: 12px
      }
      a , p{
        font-size: 13px
      }
      .border{
        border :1px solid #0f0f00 !important !important
      }
      .li-item::before{
        content: '';
        background-color: #000518;
        rotate : 45deg;
        margin-left: 10px;
        width: 8px;
        height: 8px;
        display: inline-block;
      }
      .xs-rtl{
        direction: rtl;
        text-align: right;
        margin-top: 20px
      }
      a:hover{
        text-decoration: none !important
      }
      .perfectmoney-logo{
        width: 100px !important;
        height: 100px !important;

      }
      .main-auth .logo-container-home{
        background: url('./images/logo-dark.png');
        background-repeat: no-repeat no-repeat;
        background-size: contain;
        width: 250px;
        height: 50px;
        margin-top: 8px;
        /* position:absolute; */
        display: block;
        vertical-align: center;
        /* margin-left: auto;
        align-items: center;
        vertical-align: baseline; */
      }

      .serach-box-modal .modal-content{
        margin-top: 55px;
        border-radius: 0px !important;
        height: 100%
      }
      .table td, .table th {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6
      }
      .table-borderless td, .table th {
        padding: .75rem;
        vertical-align: top;
        border-top: none !important
      }

      .row-top .icon-bell{
        color: #fff
      }
      .row-top .icon-earphones{
        color: #fff
      }
      body.light .topbar-input{
        border-color: #ced4da5e
      }
      .row-top{
        position: fixed;
        top:0px;
        z-index: 1049 !important;
        left: 0;
        right: 0 !important;
        justify-content: space-around;
        background-color: #000518
      }
      .icon-round svg{
        color: #ffffff !important
      }
      .xs-home-slider .slider-wrapper{
        height: 200px !important;
        width: calc(80%);
        color: white;
        border-radius: 25px
        /* padding-top: 70px */
      }
      .w-10{
        width: 40px
      }
      .fee-section{
        padding: 10px !important;
        margin: 0px !important;
        background-color: #fff  !important;
        border: none  !important;
        border-radius: 0px  !important;
      }
      .xs-slide{

        padding: 10px 5px 10px 5px
      }
      .carousel{
        color: white !important
      }
      .carousel.carousel-slider{
        padding-bottom: 23px;
        /* height: max-content */
      }

     .control-dots{
        margin-top: 50px !important
      }

      .arrow-signup{
        color: #fff
      }
      .badge{
        border-radius: 20px !important
      }
      .xs-login-card .left-login-col{
        width: 20%;
      }
      .modal-fullscreen .modal-content{
        width: 100% !important;
        height: 100%!important;
        top : 0;
        bottom: 0;
        border-radius: 0px !important;
        overflow: hidden !important;
        overflow-y: inherit;
      }
      .icon-row svg{
        color: #414141;

      }
      .icon-row{
        color: #9d9d9d
      }

      .badg-dark-signup  p{
        color: #fff !important;
      }
      .badg-dark-signup{
        display: flex;
        background-color: #000;
        color: #fff !important;
        border-radius: 20px;
        width: 140px;
        margin-top: 10px;
        padding: 5px;


      }
      .badg-light-green  p{
        color: #000 !important;
      }
      .badg-light-green  a{
        color: #000 !important;
      }
      .badg-light-green{
        span,svg{
          color: #000 !important;
        }
      }
      .badg-light-green:hover{
        background-color: #3ccc29;
      }
      .badg-light-green{
        display: flex;
        background-color: #58e2c7;
        color: #000 !important;
        border-radius: 20px;
        width: 140px;
        margin-top: 10px;
        padding: 5px;


      }
      .badg-dblue:hover{
        background-color: #146c94;
      }
      .badg-dblue{
        display: flex;
        background-color: #19a7ce;
        color: #000 !important;
        border-radius: 20px;
        width: 140px;
        margin-top: 10px;
        padding: 5px;


      }
      .badg-silver:hover{
        background-color: #afd3e2;
      }
      .badg-silver{
        display: flex;
        background-color: #f6f1f1;
        color: #000 !important;
        border-radius: 20px;
        width: 140px;
        margin-top: 10px;
        padding: 5px;


      }
      .animate{
        -webkit-animation: spin 5s infinite;
           animation: spin 5s infinite;
      }
      @keyframes spin{
         from {
           -moz-transform: rotateY(0deg);
           -ms-transform: rotateY(0deg);
           transform: rotateY(0deg);
         }

         to {
           -moz-transform: rotateY(-360deg);
           -ms-transform: rotateY(-360deg);
           transform: rotateY(-360deg);
         }
       }
       .actived-menuItem{
         color: #c3f618;
         cursor: pointer;

        text-align: center;
       }
       .menuItem{
         cursor: pointer;
       }
      .icon-row span{
        color: #4e4e4e;
        margin-top: 10px !important;
        font-size: 9px;
        display: block;
      }
      .xs-login-card .right-login-col{
        width: 80%;
        /* background-color: #fafafa; */
        text-align: right;
        /* background-color: #f0f0f0 */
      }
      .category-badge {
      cursor: pointer;
      padding: 3px 20px 0 20px !important;
      background-color: #162b46;
      margin: 0 5px 0 5px;
      border-radius: 15px;
    }
    .active-category-badge {
      padding: 3px 20px 0 20px !important;
      background-color: #00bc8d;
      border: 1px solid lightgray;
      margin: 0 5px 0 5px;
      border-radius: 15px;
      color: #fff;
    }
    .icon-container *{
     color: #000518 !important;
     margin-top: 5px !important
    }
    .xs-slider-box{
      /* border: 1px solid #18181840 !important; */
    }
    .xs-text-center .btn{
      width: 95% !important
    }
    .d-box-f{
      display: none !important;
    }

    .pm span{
      font-size: 14px
    }
    .rtl-flex{
      display: flex;
      flex-direction: row;
      direction: rtl;
      margin-top: 30px
    }
    .xl-d-flex{
      margin-top: 30px;
      display: grid !important
    }
    .card-transactions{
      height: 260px;

    }
    .xs-text-center{
      padding-top: 30px;
      text-align: center;
      display: flex;
      justify-content: space-between;
    }


      .topbar-input input{
        margin-top: 5px;
        border: 1px solid #ced4da5e;
        border-radius: 20px;
        color: #fff !important;
        height: 30px !important;
        background-color: #ffffff00
      }
      .carousel .control-dots .dot{
        box-shadow: none !important;
        background-color: #0f0f0050;
        width: 3px;
        height: 3px;
      }
      .control-dots .selected {
        width: 10px !important;
        background: #0f0f00;
        height: 3px !important;
    }
      .xs-icon-signup{
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        vertical-align: middle;
        align-self: center;
      }
      .xs-login-card{
        margin: 0px 0 20px 0 !important;
        padding: 13px 13px !important;
        height: 152px;
        border-radius: 15px;
      }
      .icon-round svg{
        font-size: 14px !important;

      }
      .icon-round .icon-earphones{
        font-size: 14px !important;

      }
      .icon-round .icon-bell{
        font-size: 14px !important;

      }
      .icon-round .icon-user{
        font-size: 14px !important;

      }
      .icon-round{

        padding: 7px;
        border-radius: 50%;
        align-items: center;
        vertical-align: middle;
        margin-top: 2px;
        /* width: 22px;
        height: 22px; */
        text-align: end;
        display: inline-table;
      }
      .icon-round-sm{
        background: #d1d1d1;
        padding: 5px;
        border-radius: 50%;
        align-items: center;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        text-align: end;
        margin: 0 3px 0 3px;
        display: inline-table;
      }
      .divider{
        border-bottom: 1px solid #00000020;
        margin: 0px 0 20px 0;
        height: 1px
      }

      .bg-perfect a{
        text-decoration: none !important
      }
      .btn-perfect{
        border:2px solid #d91417;
        color: #d91417;
        width: 90%;
        padding: 8px;
        background: #fff;
        margin: auto;
        border-radius: 5px;

      }
      .quote-icon-td {
        margin: 1px 0 0 -7px !important;
    }
    .small-icon {
        height: 18px !important;
        width: 18px !important;
        align-items: center;
        vertical-align: middle;
    }
      /* .xs-toggler{
        position: fixed;
        bottom: 40;
        right: 40px;
        width: 100%
      } */
      .bg-perfect{
        margin: 130px 0 50px 0;

      }
      .bg-perfect-home{
        background-color:#fff;
        height: 80px;
        background: url('./images/pm2.jpeg');
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        background-position: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px
      }
      .perfect{
        margin-top: 50px !important;
        background: url('./images/pm.png');
        /* background: url('./images/bg2.png'); */
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        height: 200px;
        width: 100%;
        background-position: center;
        padding: 0 !important;

        background-blend-mode: exclusion;
      }
      .crypto-rows{
        max-height: 750px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .Exchange-icon{

      }
      .home-slider{
        display: none !important
      }

      .landing-navbar{
        position: fixed !important;
        bottom: 0px;
        left: 0;
        right: 0;
        top: auto;
        z-index: 99
      }
      .hasbg{
      }
      .header-text-2{
        padding-right: 20px;
        font-size: 22px;
        font-weight: bold;
        text-align: right !important;
        margin-left: auto !important
      }
      .list-table{
        padding: 5px
      }
      .header-text-3{
        padding-right: 20px;
        font-size: 18px;
        font-weight: bold;
        text-align: right !important;
        margin-left: auto !important;
        text-shadow:

      }
      .gold-image{
        width: 500px;
        left: 0 !important;
        margin: auto;
      }
      .buttons{
        margin-top: 100px
      }
      .header-text{
        font-size: 35px;
        font-weight: bold;
        /* background: -webkit-linear-gradient( #000518,indigo);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
      .xl-1{
        /* height: 2000px !important ; */
        /* max-height: 2000px !important; */
        padding: 5px 5px 5px 5px
      }
      .xl-menu{
        display: none !important
      }
      .charting-container{
        /* display: none !important */
      }
      td{
        width: max-content
      }
      .card-info-cr{
        width: 100% !important ;
        top: auto;
        border-radius: 10px;
        margin-bottom: 25px;
        padding: 20px;
        opacity: 1;
        height: 220px;
      }
      .main-buttun{
        width: 90% !important
      }

      .list-switch{
        display : none !important
      }
      .navbar-brand{
        width: 400px
      }
      .serach-magnifier {
        position: relative;
        display: block;
        top: -48px;
        right: 4px;
        margin-left: auto;
        padding: 6px 0px 5px 8px;
        background-color: #99b1c9;
        border-radius: 20px;
        width: 95px;}

    .card-transactions{
      padding: 15px;
      color: #000;
      border-radius: 20px;
      height: 300px;
      overflow: scroll;
      background-color: #fafafa
    }
    .rapitex{
      font-size: 70px;
      margin-bottom:  50px;

    }
    .jumbtron{
      padding-bottom: 100px
    }
    .security-card-logo{
      width: 80px;
      height: 80px;
      background: url('./images/securepay-light.svg');
      margin: auto;
    }
    .piggy-card-logo{
      width: 80px;
      height: 80px;
      background: url('./images/piggybank-light.svg');
      margin: auto;
    }

    .wallet-card-logo{
      width: 80px;
      height: 80px;
      background: url('./images/digital-wallet-light.svg');
      margin: auto;
    }
    .card-row-left{
      height: 187px;
      width: 350px;
      padding: 100px 0 50px 0;
      margin: 50px;
      padding-top: 40px;
      border-radius: 10px;

    }
    .glaas-card {
        width: 200px;
      }
      .glaas-card-span{
        font-size: 30px
      }
    .glass-img{
      color: #000
    }
    .card-row-glass{
      bottom:  0;
      height: inherit;
      width: 350px;
      border-radius: 15px;
      z-index: -1;
      background-color: #f0f8ff69;
      position: fixed;

    }
    .header-sec-01{
      display: none;
    }
    #home04{
      background-image: linear-gradient(#00135d,black);
      background-size: cover;
      height: 400px;
      /* padding-top: 100px !important; */
      width: calc(100%) !important;
      background-repeat: no-repeat;
      background-position: center;
    }
    .card-row-right{
      height: 187px;
      width: 350px;
      padding: 100px 0 50px 0;
      margin: 50px;
      padding-top: 40px;
      border-radius: 10px;

    }
    .card-row-center{
      height: 187px;
      width: 350px;
      padding: 100px 0 50px 0;
      margin: 50px;
      padding-top: 40px;
      border-radius: 10px;
      z-index: 2
    }
    .crypto-rows{
      max-height: 750px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .glaas-card-span{
      text-align: center;
      align-items: center;
      font-size: 4rem !important;
      font-family: inherit;
      font-weight: 900;
      z-index: 9;
      /* display: block; */
      position: relative;
      top:35px;
      right: 0
    }
    .glass-img{
      text-align: center;
      align-items: center;
      font-family: inherit;
      font-weight: 900;
      z-index: 9;
      /* display: block; */
      position: relative;
      /* top:55px; */
      right: 0
    }
    .glaas-card h4{
      font-size: 7rem !important;
      font-family: inherit;
      font-weight: 900;
      z-index: 9;
      /* display: block; */
      position: absolute;
      top:55px;
      right: 5
    }
    .glaas-card-bg{
      content: "";
      background-color: #ffffff50;
      backdrop-filter: blur(5px);
    /* -webkit-backdrop-filter: blur(5px); */
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 20px;
      z-index: 1
    }
    .glaas-card-bg-2{
      content: "";
      background-color: #ffffff90;
      backdrop-filter: blur(6px);
    /* -webkit-backdrop-filter: blur(5px); */
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 20px;
      z-index: 1
    }
    .y-slogan{
      width: 250px;
      padding-bottom: 10px;
      text-shadow:
      0 0 7px #ffffff70,
      0 0 10px #ffffff70,
      0 0 21px #ffffff70,
      0 0 42px #000,
      0 0 82px #000,
      0 0 92px #000,
      0 0 102px #000,
      0 0 151px #000;
      position: relative;
      top: -55px;
      font-size: 17px;
      font-weight: 700;
      margin-right: 20px
    }
    .exchange-section{
      margin: 50px 20px !important;
      padding: 50px 20px !important;
    }
    .input-custome-button{
      font-size: 12px !important
    }
    .swap-input{
      font-size: 18px !important;
      border-right: none !important
    }
    .glaas-card{
      width: 250px;
      height: 150px;
      background-color: #ffffff30;
      position: absolute;
      top:140px;
      right: 20px;
      border-radius: 20px;
      border: 1px solid #ffe7e7;
      z-index: 3;
      color: #000518
    }
    .glaas-card-2{
      width: 200px;
      height: 200px;
      background-color: #00051830;
      position: absolute;
      top:250px;
      left: 50px;
      border-radius: 20px;
      border: 1px solid #ffe7e7;
      z-index: 2;
      color: #000518
    }
      .user-icon-navbar{

        margin-top: 10px;
      }
      .tx-modal {
        position: fixed;
      left: 0;
      top: 50px;
      height: calc(100%);
      z-index: 10;
      background-color: #000518;
      color: white;
      width: 80%;
      overflow-y: hidden;
      }
      .xs-menu a{
        text-decoration: none;
        color: white;
        font-size: 14px !important
      }
      .xs-menu a:hover{
        text-decoration: none;
        color: white
      }
    .justify{
      justify-content: space-between !important;
    }

      .button-row a{
        min-width: 100px;
        width: max-content !important;
        text-decoration: none !important;
        color: #ffffff !important;
        display: inline-block;
        margin-left: 30px;
        font-size: 14px
        /* padding: 20px 10px 20px 10px; */
      }

      .add{
        color: #a4de02;
          border-radius: 4px;
          font-size: 22pt
      }
      .button-row span{
        width: max-content !important;
        font-size: 9px !important
      }
      .pm span{
        width: max-content !important;
        font-size: 11px !important
      }
      .button-row a.active-tabs {
        color: #A4DE02 !important;
    }
      .xs-menu .dropdown-divider{
        border-top: 1px solid #e9ecef3d !important;
      }
      .xs-menu::before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: #000518 !important;
        filter: blur(5px);
        z-index: 1098;
        position: absolute;
        top:0;
        right: 0
      }

      .xs-modal-fullscreen{
        /* margin-top: 70px !important; */

      }
      .xs-menu{
      position: absolute !important;
      right: 0;
      top: 0px;
      bottom: 0px !important;

      height: calc(100% - 70px) !important;
      z-index:1099;
      /* background-color: #000518; */
      color: white !important;
      width: 87%;
      overflow-y: hidden;
      padding: 30px 30px 0 0;
      }
      .xs-navbar{
      position: fixed !important;
      right: 0;
      bottom: -8px !important;
      z-index: 1060 !important;
      background-color: #000518;
      color: white;
      width: 100%;
      overflow-y: hidden;
      padding: 30px 30px 0 0;
      }
    .marketmodal .modal-body{
      padding : 0 !important
    }
    .hover-tr svg{
      font-size: 14pt !important
    }
    .marketmodal .modal-content{
      width: 104% !important;
      height: calc(100% -50px);
      top : 53;
      bottom: 55 !important;
      border-radius: 0px !important;
      overflow-x: scroll !important;
      overflow-y: inherit;
      left: -8px !important;
      right: -8px !important;
    }
      .orderlist-container{
        height: 200px !important;
        max-height: 200px !important
      }
      .historylist-container{
        height: 220px !important;
        max-height: 220px !important;
        /* overflow: auto; */
        overflow-y : scroll;
        /* display: flex; */
        padding-left: 10px !important;
        padding-right: 10px !important;

      }
      .market-table .lgtd{
        width: max-content;
        min-width: 80px
      }
      .market-table{
        min-width: max-content;
        overflow-y : scroll;
      }
      .sticky-top-container{
        background-color: #fff
      }
      .market-container{
        padding: 10px
      }
      .order-list{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-left: 0px;
        margin-right: 0px;
      }

      .panel-wp {
        min-height: 800px;
        background-color: #f5f5f500;
    }
    .col-fit-left{
      margin: 50px 0 20px 0;
      padding: 10px;
      border-radius: 20px;
      padding: 10 0 10px 10px;
    }
    .col-fit-right{
      margin: 50px 0 20px 0;
      border-radius: 20px;
      padding: 10px
    }

    #dashboard-chart span{
      color: black
    }
    #dashboard-chart{
      background-color: inherit;
      margin: 0 15px 0 15px;
    }

    .tether-state-light .usdt-icon{
      margin-top: 9px !important
    }
    .tether-state-light{
      background-color: #fafafa !important;
      height: 50px !important
    }
    #navbar-logo{
      margin-right: 25px
    }
    .top-navbar .nav-link{
      /* margin-left: 10px;
      margin-right: 10px */
    }
    .custom-dropdown-item{
      margin-top: 10px
    }

    .nax .dropdown-menu {
      min-width: 18rem;
      padding-right: 5px !important;
      padding-left: 5px !important
    }
     .custome-dropdown-item{
      margin-top: 10px;
    }
    .crypto-rows.custom-overflow{
      height: inherit !important
    }
    .market-input-symbol{
      margin-left: 15px;
      font-weight: bold;
    }
    .market-input{
      margin-top: 10px;
      margin-bottom: 10px
    }
    #dashboard-detail{
      background-color: #fafafa;
      color: black;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 20px 15px 20px 15px
    }
    #title{
      direction: rtl;
      font-weight: bolder;
      text-align: right;
      font-size: 18px;
    }
    .slider-slide1-bg{
      content: '';
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      z-index: 1;
      background: url('./images/sbg04.png');
      background-size: cover;
      background-repeat: no-repeat no-repeat;
      background-position: right;
      opacity: 1
    }
    .slider-slide2-bg{
      content: '';
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      z-index: 1;
      background-image: url('./images/sbg05.png');
      background-size: cover;
      background-repeat: no-repeat no-repeat;
      background-position: bottom;
      opacity: 1
    }
    .ticker #tradingview_widget_wrapper::after{
      height: 32px !important;
      bottom: -2px !important
    }
    .slider-slide5-bg{
      content: '';
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      z-index: 1;
      background-image: url('./images/sbg05.png');
      background-size: cover;
      background-repeat: no-repeat no-repeat;
      background-position: top;
      opacity: 1
    }
    .slider-slide3-bg{
      content: '';
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      z-index: 1;
      background-image: url('./images/sbg04.png');
      background-size: cover;
      background-repeat: no-repeat no-repeat;
      /* filter: blur(9px); */
    }

    .slider-slide4-bg{
      content: '';
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      z-index: 1;
      background: url('./images/sbg05.png');
      background-size: cover;
      background-repeat: no-repeat no-repeat;
      background-position: center;
      opacity: 1
    }
    .slider-slide1{
      opacity: 1;
      width: calc(100%);
      height: 550px;
      z-index: 2;
      position: inherit;
      font-family: shabnam !important
    }
    .slider-slide4{
      opacity: 1;
      width: calc(100%);
      height: 550px;
      z-index: 2;
      position: inherit;
      font-family: shabnam !important
    }
    .slider-slide3{
      opacity: 1;
      width: calc(100%);
      z-index: 2;
      position: inherit;
      font-family: shabnam !important
    }
    .slider-slide2{
      opacity: 1;
      width: calc(100%);
      height: 550px;
      z-index: 2;
      position: inherit;
      font-family: shabnam !important
    }



    .main-auth form{
      padding: 0 10px 0 10px;
    }
    .wallet-card-irt{
      background-color: #D3D3D3;
      padding: 20px;
      color : white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .home-wrapper {
      padding-top: 50px;
      padding-bottom: 150px;

    }
    .wallet-card-irt-bottom{
      cursor: pointer;
      color : white;
      background-color: #708090;
      margin-top: 5px;
      padding: 10px 20px 5px 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .wallet-card-usdt{
      background-color: #B2BEB5;
      padding: 20px;
      color : white;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .wallet-card-usdt-bottom{
      cursor: pointer;
      color : white;
      background-color: #708090;
      margin-top: 5px;

      padding: 10px 20px 5px 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
      .text-medium {
        font-size: 13px !important;
        font-weight: 300 !important
      }

      .bottom-nav {
        position: fixed;
        width: 100%;
        display: flex;
        justify-content: space-between;
        bottom: 0;
        right: 0;
        background-color: white;
        z-index: 1;
        height: 64px;
        border-top: 1px solid #6593f5;
        background-color: #040d21;
      }

      .bottom-nav-item {
        background-color: #040d21;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        transition: all 0.3s ease;
      }

      .bottom-nav-item.active {
        background-color: #6593f5;
        color: white;
      }

      .bottom-nav-item--title {
        margin-top: 5px;
        font-size: 10.3px;
        margin-bottom: 0px;
      }

      .left-side{
        display: none
      }
      .left-side-xs{
        display: block !important;
        padding-bottom: 15px;
      }
      .steps-xs span{
        color: black;
        background-color: gray;
        padding: 16px 0 0 0;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: block;
          text-align: center;
          font-family: fantasy;
          /* font-weight: bold; */
          /* font-size: 14px; */
          z-index: 2;
          position: relative;
          margin: auto;
          color: white
      }
      .h-steps-last{
        content: '';
        height: 2px;
        width: 50px;
        background-color: white;
        display: block;
        position: absolute;
        z-index: 1;
      }
      .h-steps-step{
        margin: auto;
      }
      .h-steps-step1::after{
        content: '';
          height: 2px;
          width: 37px;
          background-color: white;
          display: block;
          position:absolute;
          margin-top: 13px;
          z-index: 1;
          margin-right: 50px;
          margin-top: -11px;
      }

      .steps-xs .active{
      background-color: #466bff
      }
      .w-25-xs{
        width: calc(25%) !important;
        color: white
      }
      .flex.w-25{
        width: calc(33%) !important;
      }
      .exchange-icon{
        display: block;
        margin-top: auto !important;
        margin-bottom: auto;
      }


      .coin-list-row:hover{
    background-color: rgba(17,153,255,.25)
      }
      .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 3px !important ;
        padding-left: 3px !important ;
      }

      .coin-list-row .btn{
        font-size: 12px !important;
        border-radius: 5px;
        text-align: left;
        direction: ltr;
      }
      .text-warning{
        font-weight: 80px
      }
      .market-tab{
        display: none
      }
      .market{
        padding-bottom: 80px;
        font-size: smaller
      }
      .coin-list-row.row{
        padding-right: 0px !important;
        margin-left: 10px !important;
        height: 60px;

      }
      .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        /* margin-right: -15px;
        margin-left: -15px */
      }
      .dropdown-list-row .btn-dark{
        border-radius: 3px
      }
      .dropdown-list-row{
        padding-right: 10px;
        padding-left: 10px;
        max-height: 300px;
      }
      .plus-home-icon{
        color: #9e9c9c;
      }
       .radius-sm.btn-primary{
        height: 33px !important
      }
      body.light .coin-list-row{
        font-size: 12px;
        display: block;

        display: flex;
        justify-content: space-between;

        height: 60px;
        margin-bottom: 8px;
        color: #000;
        border-radius: 2px;
        cursor: pointer;
      }
      body.dark .coin-list-row{
        font-size: 12px;
        display: block;

        display: flex;
        justify-content: space-between;

        height: 60px;
        margin-bottom: 8px;
        color: #fff;
        border-radius: 2px;
        cursor: pointer;
      }
      .coin-list-row span{
        font-size: 12px !important;
        font-weight: 200 !important
      }
      .loader-home{
        min-height: 500px
      }
      .loader-home h3{
        margin: 0;
         position: absolute;
         top: 50%;
         left: 50%;
         margin-right: -50%;
         transform: translate(-50%, -50%);
         background-color: #000518;

      }
      .loader-home{
        overflow: hidden;
        min-height: 600px;
        width: 100%;
        background-color: #000518;
        z-index: 5;
        top :0;
        left: 0;
        bottom: 0;
        position: fixed;
      }
      /* .row{
        min-width: 455px
      } */
      .btn-success{
        width: 100% !important
      }
      .round{
        width: 32px !important;
        height: 32px;
        border-radius: 50%;
        float: left;
      }
      body {
        position: relative
      }
      .xs-button{
        width: 100%;
        margin-left: 10px;
        margin-right: 10px
      }
      .coin-list-first{
        margin-top: 30px;
      }
      .list-row{
        padding: 10px 10px 0 10px
      }
      .col-sm-12{
        /* margin-top: 20px;
        margin-bottom: 20px; */
      }
      .tether-state-dark span{
        width:calc(100%);
        line-height: 2rem;
        margin-top: 20px;
        padding: 10px 0px;
        text-align: right !important;
        direction: rtl !important;
        color : white
      }
      .tether-state-dark .col{

      }
      #calc .calc-magnifier{
        position: relative;
        float: right;
        margin-top: -35px;
        margin-right: 20px;
        border: none !important
      }
      #exchange{
        /* height: 600px */
      }
      .w-xs{
        overflow-y: hidden;
        position: relative;
        width: auto !important;
        height: 50px !important
      }

      .crypto-serach{
        height: 66px
      }
      .buttons-list-row .btn{
        margin: auto;
        font-family: vazirmatn;
        /* margin: 10px 0 10px 0; */
        width: 100% !important
      }
      .r-list-button{
        margin: auto;
      }
      #calc .form-control:focus {
          color: #212529;
          background-color: #ffffff00;

          outline: 0;
          box-shadow: none;
          color: white
      }
       .form-control {
         border: 1px solid #ced4da;


      }
      #calc input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;

      }
      .svg-container{
        height: 100px
      }
      #calc input{
        border-top : none;
        border-left:none;
        border-right: none;
        border-bottom: 2px solid #177084;
        background-color: #ffffff00;
        text-align: center;
        font-size: 25px;

        color: #fafafa;
        border-radius: 0px;
        font-size: 1.2rem;
      }
      #auth-login input:focus-visible {
          box-shadow: none;

          outline: none !important;
          /* font-size: 1.2rem */
      }
      #calc input:focus-visible {
          box-shadow: none;

          outline: none !important;
          /* font-size: 1.2rem */
      }
      #usdt-darks{
        display: none !important;
        min-width: 830px;
        overflow-x: scroll;
      white-space: nowrap;

      }
      .xs-px-2{
        padding: 0 5px 0 5px
      }
      .sun-icon-sw{
        position: absolute;
        margin-top: 3px
      }
      #usdt-darks .teth-p11::before{
        background-color: #5fabff50;
        content: "";
        display: inline-grid;
        height: 3px;
        margin-left: 15px;
        rotate : 45deg;
        width: 3px;
      }
      body.light .logo-container-xs-2{
        background: url('./images/logo-dark.png');
        background-repeat: no-repeat;
        height: 50px;
        width: 250px;
        background-size: cover;
      }
      body.light .logo-container-xs{
        background: url('./images/logo-dark.png');
        background-repeat: no-repeat;
        height: 50px;
        width: 250px;
        background-size: cover;
        margin: auto;
      }
      body.dark .logo-container-xs{
        background: url('./images/logo.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 250px;
        height: 50px;
        background-position: top;
        padding-bottom: 20px
      }
      .tether-state-dark .usdt-icon{
        width: 50px;
        height: 50px;
        background-size :contain;
        margin-top: 10px;
        margin-bottom: auto;
      }
      #home04 p::after{
        background-color: #ffd400;
        content: "";
        display: inline-grid;
        height: 7px;
        margin-left: 10px;
        rotate : 45deg;
        width: 7px;

      }
      #home04 p{
        font-family: vazirmatn;
        line-height: 2rem
      }
      #home04{

        margin-top: 50px;
        padding-bottom: 50px;
        height: 840px !important
      }
      .tether-state-dark .teth-p{
        display: grid;
        color: white;
        line-height: 1rem;
      }
      .teth-p{
        font-size: 10px;
        color: black;
        display: flex;
        justify-content: space-between;
      }
      .c-i{
        color: inherit;
        font-family: vazirmatn;
        font-weight: 400;
        text-align: center;
        margin: auto;
        font-size: 20px;
        display: block;
      }
      #home03{
        /* min-width: 500px; */
        overflow-x: scroll;
        display: flex;
        font-size: 12px;
        display: flex;
      }

      .fiat-span{
        font-weight: bolder;
        float: left;
      }
      .mobile-nav{
        display: none !important;
        border-top: 1px solid purple;
        border-top-left-radius : 10px;
        border-top-right-radius : 10px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: purple
      }
      body {
        /* background-color: #040d21; */
        /* color : white; */
        overflow-x: hidden;
        width: auto!important;
        overflow-y: scroll;
        scroll-behavior: smooth !important;
      }
      .has-border-withdrawal{
        margin: 50px 0 0 0 !important;
        color: black
      }
      .w-75{
        width: 100% !important
      }
      .sidebar{
        display: none !important;
        height: calc(100% - 63px);
        width: 100%;
        background-color: #000518;
        color: white;
        position: fixed;
        z-index: 2;
        top: 0 !important;
        overflow: hidden !important;
        padding: 50px 20px 10px 20px;
        font-family: vazirmatn;
        text-align: right;
        direction: rtl;

      }
      .panel-wp{
        padding-bottom: 100px
      }
      .container {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }


    #auth-login{
      /* padding: 25px 0px 50px 0px */
    }
    #auth-login hr{
      display: none
    }
    td span{
      margin: auto;
    }
    .xs-md-icon{
      /* position: absolute; */
    }
    .xs-md-coin-name{
      margin-top: 40px;
      max-width: 100px;
      margin-right: 3px;
      font-size: 11px;

    }
    .coin-name{
      margin: 7px 4px 0 0
    }
    #auth-login input:focus-visible {
        outline-offset: 0px;
        box-shadow: none;

        outline: none !important;
        /* padding: 3px 5px 3px 5px; */
        /* font-size: 1.2rem */
    }
    #auth-login .btn{
      margin: 8px 0 20px 0;
    }
    .redirect p{
      margin-top: 30px;
      color:#177084 !important
    }
    .authentication img{
      margin-bottom: 50px
    }
    .d-flex-xs{
      display: inherit !important
    }
    .h-100 .d-flex{
      display: grid;
    }
    #auth-login input{

      border: 1px solid #177084;
      background-color: #ffffff00;
      /* color: white; */
      border-radius: 5px

    }
      #form .form-list{
        padding-left: 65px;
      }
      #merchant::before{
        content: '';
        background: url('./images/lines-hero.svg');
        background-size:cover;
        width: 50px;
        height:calc(100% - 20px);
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        margin-top: -58px;
        /* margin-left: -74px; */
      }
      .h-100{
        min-height: max-content !important
      }
      .absolute{
        position: absolute;
        margin-right: -5px;
        margin-top: 3px
      }
      #merchant::after{
        content: '';
        background: url('./images/coding-icon.svg');
        background-size:cover;
        width: 40px;
        height:30px;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        margin-top: 100px;
        box-shadow: 1px 1px 30px 10px #888888;
        /* margin-top: -58px; */
        /* margin-left: -74px; */
      }
      #form::after{
        content: '';
        background: url('./images/coding-icon-dark.svg');
        background-size:cover;
        width: 40px;
        height:30px;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        /* margin-top: -100px; */
        box-shadow: 1px 1px 30px 10px #888888;
        /* margin-top: -58px; */
        /* margin-left: -74px; */
      }
      .w-xs .usdt-icon{
        margin-top: -5px
      }
      .w-xs tr{
        padding: 10px 15px;
        display: flex;
        height: 40px;

      }
      .w-xs .td{
        min-width: 180px !important;
        max-width: max-content !important;
        text-align: center;
        box-sizing: content-box;
      }
      #form::before{
        content: '';
        background-image: linear-gradient(green,white,indigo);
        width: 3px;
        height: calc(100% - 80px);
        position: absolute;
        z-index: 3;
        margin-top: -40px;
        margin-left: 19px;
      }
      #Wallet::before{
        content: '';
        background-image: linear-gradient(green,white,indigo);
        width: 3px;
        height: calc(250%);
        position: absolute;
        z-index: 3;
        margin-top: -40px;
        margin-left: 19px;
      }
      .hero-icon::before{
        content: '';
        background: url('./images/zap.svg');
        width: 354px;
        height: 50px;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 60px;
      }
      .hero-icon-2::before{
        content: '';
        background: url('./images/zap.svg');
        width: 300px;
        height: 50px;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 50px;
      }
      .hero-icon-3::before{
        content: '';
        background: url('./images/zap-2.svg');
        width: 65px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 30px;
      }
      .hero-icon-4{
        width:calc(50%);
        padding: 10px 20px;
        text-align: center;
        margin: auto;
      }
      .hero-icon-4::before{
        content: '';
        background: url('./images/zap-2.svg');
        width: 200px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: -66px;
        margin-top: 40px;
        position: absolute;
        margin-right: -38px;
      }
      #gradient{
        margin-bottom: -1px
      }
      #merchant , #Wallet , #form ,#supported-crypto{
        padding-top: 70px;
        padding-bottom: 100px
      }
      .section1-xl {
        display: none !important
      }
      .hero-text{
    font-size: 48px;
        font-variant: unicase
      }
      #merchant img{
        display: none !important;
      }
      #Wallet img{
        display: none !important;
      }
      .section1-xs{
        display: block !important
      }
      .globe-home{
        position: inherit;
        text-align: -webkit-center !important

      }
      .navbar-text{
        margin-top: 10px !important
      }
      .row{
        --bs-gutter-x : 0px
      }
      a:hover{
        text-decoration: none
      }

      .logo-container{
        background: url('./images/logo.png');
        background-repeat: no-repeat;
        height: 32px;
        display: flex;
        width: 200px;
        left:0;
      }
      .bg-shadow{
        margin-left: 35px;
        height: 75px;
        box-shadow:  1px 0px 73px 28px #00bccdbd;
    }
      .registerd-note::before{
        content: '';
      width: 0px;
      margin-left: -12px;
      height: 75px;
      box-shadow: -35px 0px 228px 180px #00bccdbd;
      rotate: 45deg;
      position: absolute;
    }
          .globe-home canvas{
        width: calc(100% -100px) !important;
        height: calc(100% -100px) !important;
        z-index: 3
      }
      .bg-gray{
        background-color: #fafafa
      }
      .curve-svg{
        width: 100%;
        margin-bottom: -2px
      }
      #Wallet img{
        width: calc(100%);
        margin-top: 20px
      }
      #Wallet .main-button{
        width: 100% !important;
      }
      #installation .main-button ,.m-btn-g{
        width: 100% !important;
      }
      #form h1{
        font-family: sans-serif !important;
      }
      #form .text-subject2{
        font-size: 16px !important;
      }
      #form .icon-lock{
        font-size: 10pt !important;
        margin-top: 5px !important
      }
      #installation .btn{
        width: calc(90%)
      }
      #circular .col {
        text-align: -webkit-center !important
      }
      .hasbg{
        height: 730px !important
      }
      #footer{
        display: none;
        padding-top: 50px;
        /* background-color: indigo */
      }

      #footer a  p{
        text-decoration: none;
        color: white;

      }
      #supported-crypto .w-75{
        width: calc(96%) !important
      }
    #merchant li{
      line-height: 3rem
    }
    #merchant .btn{
      width: 95% !important;
      margin: auto;
      margin-top: 50px
    }
    #form .btn{
      width: 95% !important;
      margin: auto;
    }
    #installation .btn{
      width: 95% !important;
      margin: auto;
    }
    #circular{
      margin-top: 100px
    }
    .menuBox a{
        color: #fff ;
        margin-left: 30px;
        display: inline-block;
        text-align: center;
      }

    .light-search .input-span{
      position: absolute;
      margin-top: -37px !important;
      right: 20px;

    }
    .input-span{
      margin-top: -32px;
      margin-right: 20px;
      position: fixed;
    }

    -box-f{
      display: none !important
    }

    .scrollHider-gutter
    {
      display:flex !important;
      width:100%;
      overflow:auto;
      overflow-x: scroll;
      overflow-y: hidden;
      margin-top: 15px;
      margin-bottom: 15px;
      border-left: none !important;
      border-right: none !important;
      padding: 10px;
      left: 0 !important;
    }
    .card{
      background-color: none;
      border: none
    }

    .d-box-f-xs
    {
    display: flex !important;
    width: 100%;
    /* overflow-y: hidden; */
    overflow-x: scroll;
    background-color: #fff;
    color: #000;
    margin: 20px 0 !important;
    align-items: center;
    position: relative;
    height: 80px;
    border-radius: 10px;
    align-items: center;
    vertical-align: middle;
    }
    .market .border-bottom{
      /* border-bottom: 1px solid #5f5f5f !important */
    }

    .usericon{
      width: 65px;
      height: 65px
    }


    .market-input-symbol{
      cursor: pointer;
      position: absolute;
      margin-top: -48px;
      left: 10px;
      font-size: 14px;
      /* width: 22px; */
      height: 17px;
      text-align: left;
      direction: ltr;
      color: #00000070;
      display: block;
      margin-right: auto;
      font-size: 9px;
      background-color: #fff;
      padding: 3px 5px 0 5px;
    }
    .menuItem
    {   margin-top: 10px;
        max-width:100px;
        width: max-content;
        text-align:center;
    }
    .market-header{
      background-color: #fff !important;
      color: inherit;
      padding: 10px 20px 10px 20px;
      margin: 10px 0;
      border-radius: 3px;
      border: 1px solid #f5f5f530;
      border-bottom: 1px solid #f0f0f0
    }
    .orderlist-container-fullheight{
      height: 400px
    }
    .xl-navbar{
      display: none !important
    }
    .withdrawal:not(.show) {
      height: 0px !important;
      border-top: #ffffff20
    }
    .withdrawal{
    background-color: white;
    color : black;
    width: 100%;
    height: 350px;
    margin: auto;
    position:fixed;
    bottom: 0;
    z-index: 5;
    transition: height 0.5s ease-in-out;
    transition: border-top 0.8 ease-in-out;
    border-top: 2px solid #000222;
    -webkit-clip-path: polygon(54% 33%, 100% 50%, 100% 100%, 0 100%, 0 50%);
    clip-path: polygon(54% 33%, 100% 50%, 100% 100%, 0 100%, 0 50%);
    }
    .gateway-wrapper .card-body{
      /* color: white !important; */
      background-color: #ffffff;
      color : #000;
    }
    .crypto-list{
      position: absolute !important;
      width: 500px;
      height: 200px;
      overflow-y:scroll;
    }
    .card-gradiant-blue{
      margin-top: 50px;
      margin-inline : 20px
    }
    .card-gateway{
      display: flex !important;
        justify-content: space-between !important;

      width: calc(100%) !important
    }
    .side-item{
      display: flex;
    }
    .side-item span{
      margin-top: -6px !important;
      margin-left: 10px !important;
      cursor: pointer;
    }
    .btn-step{
      background-color: #000222;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      outline: 1px solid #000222;
      border-radius: 20px;
      width: 200px;
    }
    .btn-step:hover{
      background-color: #01054e;
    }
    .btn-step:active{
      background-color: #01054e;
    }
    .btn-step:focus{
      background-color: #01054e;
    }
    .step2-voucher{
      width: 100px;
      height: 100px;
      background: url('./images/voucher.svg');
      background-size: contain;
      display: inline-grid;
      position: relative;
      cursor: pointer;
    }
    .step2-crypto{
      width: 100px;
      height: 100px;
      background: url('./images/cryptocurrency.svg');
      background-size: contain;
      display: inline-grid;
      position: relative;
      cursor: pointer;
    }
    .step1-gateway{
      width: 100px;
      height: 100px;
      background: url('./images/gateway.svg');
      background-size: contain;
      display: inline-grid;
      position: relative;
      cursor: pointer;
    }
    .step1-form{
      width: 100px;
      height: 100px;
      background: url('./images/form.svg');
      background-size: contain;
      display: inline-grid;
      cursor: pointer;
    }
    .panel-wp {
      min-height: 800px;
      /* background-color: #ecf4fa */
    }
    .card-light-left-section{
      margin-left: 20px;
      margin-right: 20px
    }
    .drop-down-gateway{
      z-index: 4 !important
    }
    .otp-container{
    display: flex;
    justify-content: space-between;
    }
    #otp input{
    font-size: 1.5rem;
    text-align: center;
    }
    #otp input:focus{
    border: none !important;
    box-shadow: none !important;
    text-align: center;

    }
    .chart-container{
    display: none;
    }
    #otp input:active{
    text-align: center;
    }
    #otp .input-group-text{
    background-color: #139f00;
    color: white;
    border: 1px solid #139f0050
    }
    .btn-success{
    background-color: #139f00;
    color: white;
    border: 1px solid #139f0050
    }
    .otp-container{
    width: calc(75%);
    margin: auto;
    text-align: center;
    }
    /* .otp-input input{
    width: 30px !important;

    } */
    .ht {
      min-height: calc(100% - 300px)
    }

    .sidebar-item :hover {
      scale: 1.1
    }

    .sidebar-item {
      display: flex;
      margin-top: 20px;
    }
    .sidebar-item p{
      color: #000;
      font-size: 13px
    }
    .sidebar-x{
      padding-bottom: 50px;
      overflow-y: scroll;
      height: calc(100% + 50px);
    }

    .interior-menu-width p {
      margin-top: 5px;
      margin-bottom: 0;
      margin-left: 15px;
      font-weight: 100
    }

    .interior-menu-width {
      width: 200px !important;
      transition: width 0.5s;
      min-height: 800px;
      max-height: calc(100%);
      background-color: #181818;
      padding: 10px 20px 10px 20px;
      border: 1px solid #fafafa10;
      border-radius: 0px;
      position: fixed;
      z-index: 3;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      margin-top: 50px;
      font-size: 1rem
    }

    #arrow-menu-right .icon-arrow-right, .icon-arrow-left {
      content: '';
      width: 5px;
      height: 5px;
      background-color: #ffffff00;
      margin-top: 5px;
      font-weight: bold;
      margin-left: 4px;
      font-weight: 100;
    }
    .mt-10{
      margin-top: 100px;
    }
    #arrow-menu-right {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      margin-top: -30px;
      right: -11px;
      box-shadow: 0px 0px 20px 1px gray;
      cursor: pointer;
    }

    .rotate-180 {
      transform: rotate(180deg);
    }

    .topbar-menu {
      display: none
    }

    .icon-shadow {
      shadow: 0px 0px 17px white
    }

    .menu-item-containers {
      margin-top: 100px
    }

    .interior-menu {
    display: none;
    }

    .bg-transparant {
      background-color: #ffffff00 !important
    }

    .fixed-top {
      position: fixed;
      top: 0;
      left: 0;
      right: 0
    }

    .pointer {
      cursor: pointer;
    }

    .panel-container-left {

    }

    .panel-container-right {
      display: contents;
      padding-bottom: 100px
    }
    .content .card a{
      text-decoration: none;
    }
    .content .card{
      background-color: #ffffff00 !important;
      color: black;
      padding-left: 20px;
      padding-right: 20px
    }
    .text-small{
      font-size: 12px
    }
    .panel-container {
      display: grid;
      min-width: calc(100%);
    }

    .content {
      width: auto;


    }

    .content-section-1 {
      width: 75%;
      margin: auto;
    }

    .img-section-sized {
      width: calc(100%)
    }

    .justify {
      justify-content: space-between;
    }



    #merchant li {
      line-height: 3rem
    }

    #merchant .btn {
      width: 100% !important;
      margin: auto;
      margin-top: 50px
    }

    #installation .btn {
      width: 100% !important;
      margin: auto;
      margin-top: 50px
    }

    #form li {
      line-height: 3rem
    }

    #form .btn {
      width: 50% !important;
      margin: auto;
      margin-top: 50px
    }

    #Wallet li {
      line-height: 3rem
    }

    #Wallet .btn {
      width: 100% !important;
      margin: auto;
      margin-top: 50px
    }

    .lh-xl {
      line-height: 3rem
    }

    .title-m {
      font-size: 36px;
      font-weight: 200;
      font-style: oblique;
    }



    #footer a {
      text-decoration: none;
      color: gray;
      font-weight: 100;
    }

    #footer a :hover {
      color: black;
      font-weight: 400;
    }
    .footer{
      padding-bottom: 50px
    }

    .section1-lg .section1-md .section1-sm .section1-xs {
      display: none !important
    }

    #hero .hero-text {
      margin-top: 5rem;
    }
    #heroxs .px-3{
      padding-right: 0px !important;
      padding-left: 0px !important
    }
    /* #root section {
      padding-left: 10px;
      padding-right: 10px
    } */
    #heroxs .xs{
      display: none !important;
    }
    #circular img, #svg{
      display: none !important
    }
    .animation-text{
      padding-left: 0px
    }
    .bg-gray {
      background-color: #fafafa;
    }
    .input-group-text{
      background-color: green;
      border: none;
      color : white
    }
    .h-400 {
      min-height: 400px
    }

    .panel-container-left .card{
    background: #000
    }
    #supported-coins .card {
      border-color: #fafafa50 !important
    }
    .justify-content-space-between{
    justify-content: space-between;
    }


    #hero .input-group-text {
      background-color: #139f00;
      border-color: #139f00;
      color: white
    }

    .radius-sm:hover{
      border-radius: 3px
    }
    .radius-sm{
      border-radius: 5px;
      padding: 5px;
    }
    .login-btn-navbar a {
      text-decoration: none !important;
    }
    .navbar{
      height: 78px;
      padding-bottom: 5px !important
    }
    .login-btn-navbar {
      margin-left: auto !important;
      padding: 5px 30px 5px 30px;
      background-color: #ffffff00;
      color: white;
      border: 1px solid #fafafa;
      border-radius: 5px
    }
    }
    @media (max-width : 401px){
      html, body {
        overflow-x: hidden !important;
        width: auto!important;
        position: relative !important;
      }
      .horizental-menu {
        height: 0px !important;
    }
      .globe-home{
        display: none
      }

      .perfectmoney-logo{
        width: 100px !important;
        height: 100px !important;

      }
      .navbar{
        height: 60px;
        padding-bottom: 15px !important
      }

      .bg-perfect{
        background-color:#fff;
        height: 400px
      }
      .xs-list-markets{
        height: max-content;
        overflow: auto;
      }
      .main-auth .logo-container-home{
        background: url('./images/logo-dark.png');
        background-repeat: no-repeat no-repeat;
        background-size: contain;
        width: 250px;
        height: 50px;
        margin-top: 8px;
        /* position:absolute; */
        display: block;
        vertical-align: center;
        /* margin-left: auto;
        align-items: center;
        vertical-align: baseline; */
      }

      .perfect{
        margin-top: 50px !important;
        background: url('./images/pm.png');
        /* background: url('./images/bg2.png'); */
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        height: 200px;
        width: 100%;
        background-position: center;
        padding: 0 !important;

        background-blend-mode: exclusion;
      }
      .navbar-collapse.show{
        z-index: 99999 !important;
        background-color: #fff !important
      }
      #heroxs .display-2 {
        padding-top: 3rem;
        padding-bottom: 5rem;
        font-size: 30px;
        font-weight: bolder;
      }
      #heroxs .px-3{
        padding-right: 0px !important;
        padding-left: 0px !important
      }
      .landing-navbar{
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        z-index: 99
      }

}
