
@media (min-width: 1199.99px) {
  .ticker-list-2 .btn{
    height: 50px;
    align-content: center;
    align-items: baseline;
  }
  .btn.form-control{
  border-radius: 3px
  }
  input.form-control{
    border-color:#495057 !important;
    height: 43px
  }
  .logo-sidebar{
    width: 240px !important;
    position: absolute;
    left: 3 !important;
    top: 3 !important;
  }
  .main-sidebar{
    position: fixed !important;
    right: 0 !important
  }
  span.ps-menu-label{
    margin-left: 5px
  }
  .form-search{
    border-radius: 35px;
    font-size: 16px;

  }
  .main-sidebar{
    display: flex;
    position: fixed;
    z-index: 1033;
    height: 100%;
    right: 0;
    top: 0;
  }
  .address{
    color: #fff;
  }
  .phone{
    color: #fff;
  }
  .dropzone{
    border: 1px dotted black;
    padding: 10px 5px;
    border-radius: 3px;
    margin-left: 3px;
    margin-right: 3px
  }
  .thumb-img{
    width: 100px;
    height: 100px;

  }
      .small, small {
        font-size: 11px;
        font-weight: 400
      }
      .coin-list-row .btn{
        height: 35px;

      }
      .btn-group .btn{
        border-radius: 0px
      }
      .dashboard-row-1 .col-fit-left{
        height: 200px;
      }
      .dashboard-row-1 .col-fit-right{
        height: 200px;
        padding: 10px
      }
      .border-light{
        border-radius: 5px;
        padding: 10px
      }
      .market{
        margin-top: 0px
      }
      .btn{
        border-radius: 3px
      }
        body {

          overflow-x: hidden;
          scroll-behavior: smooth !important;
          margin: 0 !important;
          font-size: 12px;
        }
.market-container{
  min-height: 550px;
  max-height: 100
}
        .h-300{
          max-height: 300px !important
        }
        body.light{
          .card{
            background-color: #fafafa;
            min-height: 600px;
            padding-bottom: 50px;
          }
          .card-header {
            background-color: #19253e !important;
            color : white;
            border-bottom: #fafafa !important
          }

        }
        .list-coin-slug-flex{
          margin-inline: 36px;
          margin-top: 3px;
          display: block;
          width: max-content;
          position: absolute;
          font-size: 12px
        }
        .border-right-xl{
              border-right: 1px solid #fafafa50;
        }
        .pr-5-xl{
          padding-right: 50px
        }
        .tx-detail.modal-dialog{
          left: 0 !important;
          right: auto;
          margin: 0 auto 0 0 !important;
          position: fixed;
          width: 700px !important;
          height: 100% !important
        }
        .tx-detail .modal-content{
          position: fixed;
          margin-left: 0 !important;
          margin-right: auto;
          border-bottom: 0;
          height: 100%;
          border-radius: 0 !important;
        }
        .wallet-card{
          background-color: #fafafa;
          margin: 10px;
          padding: 10px;
          color: #000;
          min-height: 300px;
          border-radius: 10px;
          direction: rtl;
        }
        .ticker-list-2{
          background-color: white !important
        }
        /* .hue::before{
          content : '';
          background: url('./images/hero-glow.svg');
          background-position: top !important;
          background-repeat: no-repeat;
          opacity: 0.2;
          z-index: 0;
          position: absolute;
          width: 100%;
          height: 200%;
          display: block;
          background-color: inherit;
        } */
        .hue{
          position: static;
          height: max-content;
          z-index: 2
        }
        .xs-left{
          margin : 10px;
        }
        /* .hue{
          background-color: #f0f0f0
        } */
        .main-auth.hue{
          background: url('');
        }
        .border {
          border: 1px solid #dee2e6 !important
        }

        .border-top {
          border-top: 1px solid #dee2e6 !important
        }

        .border-right {
          border-right: 1px solid #dee2e6 !important
        }

        .border-bottom {
          border-bottom: 1px solid #6059595e !important
        }

        .border-left {
          border-left: 1px solid #dee2e6 !important
        }

        .border-0 {
          border: 0 !important
        }

        .border-top-0 {
          border-top: 0 !important
        }

        .border-right-0 {
          border-right: 0 !important
        }

        .border-bottom-0 {
          border-bottom: 0 !important
        }

        .border-left-0 {
          border-left: 0 !important
        }

        .border-primary {
          border-color: #007bff !important
        }

        .border-secondary {
          border-color: #6c757d !important
        }

        .border-success {
          border-color: #28a745 !important
        }

        .border-info {
          border-color: #17a2b8 !important
        }

        .border-warning {
          border-color: #ffc107 !important
        }

        .border-danger {
          border-color: #dc3545 !important
        }

        .border-light {
          border:1px solid #f0f0f0 !important
        }
        .light-border-bottom {
          border-bottom-color: #6059595e !important;
          border-bottom:
        }
        .border-dark {
          border-color: #343a40 !important
        }

        .border-white {
          border-color: #fff !important
        }

        .exchange-input-symbol-left{
          position: relative;
            float: left;
            left: 10px;
            top: -30px;
            color: #fff;
            z-index: 1020;
            padding-left: 5px
        }
        .card-transactions{
          height: 200px;
          overflow: scroll;
          overflow-y: scroll;
        }
        .modal .modal-content {
          border: 0;
          border-radius: 15px;
        }
        .xl-ltr{
          direction: ltr !important;
          text-align: left;
        }
        .xl-d-flex{
          display: flex !important
        }
        .rtl-flex{
          display: flex;
          flex-direction: row;
          direction: rtl;
          margin-right: auto
        }
        .navbar-dark{
          background-color: #0b1a2f !important
        }
        .input-custome-button span{
          font-size: 14px
        }

        .input-custome-button{
          border: 1px solid #ced4da !important;
          border-left-width:  2px !important;
          background-color: #f9f9f910 !important;
          width: 212px;
          height: 48px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px
        }
        .header-text-3{
          text-align: center !important;
          margin-left: auto;
          margin-right: auto;
          align-self: center;
        }
        .header-text-2{
          text-align: center;
        }
        .horizental-menu{
          height: 60px
        }
        .input-group  .dropdown-menu.show{
          width: 400px;
          font-size: 14px
        }
        .button-row .active-tabs{
          color : #A4DE02 !important ;
          border-bottom: 2px solid #A4DE02;
          padding-bottom: 10px
        }
        .active-tab{
          min-width: 100px;
          color : #A4DE02 !important ;
          border-bottom: 1px solid #A4DE02;
          padding-bottom: 11px
        }
        .button-row span{
            width: 100px !important;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            margin: 0 10px 0 10px
        }
        .xs-navbar{
          display: none !important
        }
        .button-row a{
          min-width: 100px !important;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        text-decoration: none !important;
        color: #ffffff !important
        }
        .left-auto{
          text-align: end
        }
        .button-row{
          max-height:  45px;

        }
        .header-text-2{
          padding-right: 20px;
          font-size: 22px;
          font-weight: bold;
          /* text-align: right !important; */
          margin-left: auto !important
        }
        .quote-icon-td{
          margin: -3px 0 0 -8px !important
        }

        .glaas-card-span{
          text-align: center;
          align-items: center;
          font-size: 6rem !important;
          font-family: inherit;
          font-weight: 900;
          z-index: 9;
          /* display: block; */
          position: relative;
          top:55px;
          right: 0
        }
        .glass-img{
          text-align: center;
          align-items: center;
          font-family: inherit;
          font-weight: 900;
          z-index: 9;
          /* display: block; */
          position: relative;
          /* top:55px; */
          right: 0
        }
        .glaas-card h4{
          font-size: 7rem !important;
          font-family: inherit;
          font-weight: 900;
          z-index: 9;
          /* display: block; */
          position: absolute;
          top:55px;
          right: 5
        }
        .glaas-card-bg{
          content: "";
          background-color: #ffffff50;
          backdrop-filter: blur(5px);
      /* -webkit-backdrop-filter: blur(5px); */
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 20px;
          z-index: 1
        }
        .glaas-card-bg-2{
          content: "";
          background-color: #ffffff90;
          backdrop-filter: blur(6px);
      /* -webkit-backdrop-filter: blur(5px); */
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 20px;
          z-index: 1
        }
        .glaas-card{
          width: 300px;
          height: 200px;
          background-color: #ffffff30;
          position: absolute;
          top:90px;
          left: 200px;
          border-radius: 20px;
          border: 1px solid #ffe7e7;
          z-index: 3;
          color: #000518
        }
        .glaas-card-2{
          width: 200px;
          height: 200px;
          background-color: #00051830;
          position: absolute;
          top:250px;
          left: 50px;
          border-radius: 20px;
          border: 1px solid #ffe7e7;
          z-index: 2;
          color: #000518
        }
        .header-text-3{
          padding-right: 20px;
          font-size: 18px;
          font-weight: bold;
          /* text-align: right !important; */
          margin-left: auto !important
        }
        .horizental-menu{
          position: absolute !important
        }
        .xl-menu{
          height: 60px;
          vertical-align: middle;
          display: flex;
        }
        .jumbtron{
          text-align: center;
          margin-bottom: 50px
        }
        .card-header{
          max-height: 45px;
          border-top-right-radius: 10px !important;
          border-top-left-radius: 10px !important
        }
      .navbar{
        height: 60px !important;
        /* padding: 10px !important */
      }
      .order-system{
        display: none;
      }
      .landing-navbar{
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        z-index: 100
      }
      .header-text{
        font-size: 60px;
        font-weight: bold;
        /* background: -webkit-linear-gradient( #000518,indigo);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
      .market{
        padding-top: 60px;
      }
      .history-col{
        display: flex !important;
        justify-content: space-between !important
      }
      .market-list{
        height: 500px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 5px !important
      }
        .col-fit-right{
          padding : 10px;
          margin-left: 20px;
          height: 220px;
          padding-left: 10px;
          border-radius: 10px;
          background-color: #fff
        }
        .col-fit-left{
          padding : 10px;
          height: 220px;
          border-radius: 10px;
          background-color: #fff
        }
        .usdt-home-container{

        }
        .usdt-container{
          height: 50px !important;
          align-items: center;
          direction: rtl;
          padding: 0 20px 0 20px;
        }
        .navbar .dropdown-menu{
          width: 270px !important;
          top: 101% !important;
          left: -8px !important;
          padding: 10px 0 20px 0
        }
        .usdt-container p{
          margin-bottom: 0px !important;
          margin-bottom: 0px !important
        }

        .swap-dropdown{
          width: calc(100%) !important
        }
        .stick-top-header{
          position: sticky;
          top: 133px;
          background-color: #fff;
          /* border-top: 1px solid #fff;
          border-bottom: 1px solid #fff; */
          z-index: 10
        }
        #auth-login{
          padding-bottom: 50px;

        }
        #dashboard-detail{
          margin-bottom: 15px;
          margin-top: 15px;
        }

        .terms{
          padding: 10px;
        }
        .card-body-xl{
            background-color: #f5f5f5;
        }
        .tx-modal .card-body{
          background-color: #f5f5f5;
          padding: 25px;
        }

        .market-modal .modal-content{
          margin: 0 !important;
          padding:  0 !important;
          border-radius: 0 !important;
          position: fixed !important;
          top: 0 !important;
          left: 0 !important;
          width: 700px;
          height: 100%
        }
        .market-modal.modal-dialog{
          background-color: #f5f5f5;
          padding: 25px;
          position: fixed !important;
          top: 0 !important;
          left: 0 !important;
          bottom: 0 !important;
          width: 700px;
          margin: 0 !important;
          padding:  0 !important;
          border-radius: 0 !important

        }

        .button-row a{
          text-decoration: none;
          padding: 0 10px 0 10px
        }
        .button-row span{
          width: 100px !important;

        }
        .sub-menu-sidebar .submenu-item a{
          color: #f5f5f5 !important;
        }
        .sidebar-subitem-active:hover{
          color: #58e2c7 !important;
        }
        .sidebar-subitem-active a:hover{
          color: #58e2c7 !important;
        }
        .sidebar-subitem-active a{
          color: #58e2c7 !important;
        }
        .tab-inline{
          width: 100px;
          padding-bottom: 10px;
          color: #000;
          text-align: center;
        }
        .perfectmoney .title{
          margin-top: 20px
        }
        .perfectmoney .col-xl-7{
          padding: 10px
        }
        .active-tab-inline{
          width: 100px;
          padding-bottom: 10px;
          border-bottom: 1px solid blue;
          color: blue;
          text-align: center;
        }
        .sidebar-subitem-active::after{
          content: '';
          width: 10px;
          height: 10px;
          margin-right: auto;
          rotate :-45deg;
          border-left:1px solid #58e2c7;
          border-top:1px solid #58e2c7;
          margin-top: 8px
        }
        .sidebar .dropdown-divider{
              border-top: 1px solid #373e46;
        }
        .sidebar-subitem-active{
          color: #58e2c7;
          /* background-color: #162b46; */
          border-radius: 20px;
          display: flex;
          padding:10px ;

        }
        .sub-menu-sidebar .submenu-item{
          display: flex;
          padding:10px ;
          font-size: 12px !important;
          opacity: 1 !important
        }
        .sub-menu-sidebar{
          color: #fff;
          text-align: right;
          direction: rtl;
          min-height: 50px;
          padding-right: 30px;
        }
        .icon-market{
          width: 45px !important;
          margin-top: 10px
        }
        .marketmodal{
          margin: auto !important;


        }
        .marketmodal .modal-content{
          font-family: vazirmatn;
          min-height: 500px !important;
          max-height: max-content !important;
          margin: auto !important;
          top: 100px !important;
          border-radius: 5px !important;
          min-width: 800px !important;
        }
      .marketmodal-modal-dialog {
        min-width: 100% !important;
        text-align: center !important;
        align-items: center !important;
        left: auto !important;
        right: auto !important;
      }
      .modal-dialog{
        margin-top: 8rem !important
      }
        .help{
          padding: 10px;
        }
        .btn-block-help{
          border-radius: 0px !important;
          text-align: right !important;
          direction: rtl;
        }
        .stick-top{
          position: sticky;
          top:-8px;
          background-color: white;
          padding-bottom: 0px;
          margin-bottom: 20px
        }
        .main-logo-dark{
          height: 50px;
          width: 250px;
          display: block;
        }
        .main-logo-light{
          display: none !important
        }
        .tradingview-widget-container{
          margin-left: -15px;
          left: 0 !important;
          height: 300px !important;
          /* width: 600px; */
          /* width: calc(100%) !important; */
          /* height: 400px; */
        }
      .orderlist-container-fullheight{
        height: 500px;
        overflow: auto;
        overflow-y: scroll;
      }
        /* .ordering-section .col{
          padding-left: 0 !important;
          padding-right: 0 !important
        } */
        .wallet-card-irt{
          background-color: #f0f0f0;
          padding: 20px;
          color : #36454F;
          margin-left: auto;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }


          .wallet-card-irt-bottom{
            cursor: pointer;
            color : #36454F;
            background-color: #708090;
            margin-top: 2px;
            margin-left: auto;
            padding: 10px 20px 5px 20px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }


        #dashboard-chart{
          margin: 30px 0  60px 0;
          padding: 10px;
          border: 1px solid #f0f0f0;
          border-radius: 10px;
          background-color: #fff
        }

        .card-transactions{
          background-color: #fff;
          padding: 10px 10px;
          color : white;
          /* margin-left: 10px; */
          border-radius:  10px;
          min-height: 220px;
          height: 220px
        }
        .card-logs{
          background-color: #007bff ;
          padding: 10px 10px;
          color : white;
          margin-left: 10px;
          border-radius:  10px;
          min-height: 200px;
        }

        .wallet-card-usdt{
          background-color: #f0f0f0;
          padding: 20px;
          color : #36454F;
          margin-right: 10px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        .wallet-card-usdt-bottom{
          cursor: pointer;
          color : #36454F;
          background-color: #708090;
          margin-top: 2px;
          margin-right: 10px;
          padding: 10px 20px 5px 20px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .slide-title{
          margin-top : 30px;
          padding-top : 10px;
          direction: rtl;
          color: white;
          font-size: 48px;
          margin-left: auto;
          margin-right: 100px;
          text-align: right;
          font-family: shabnam;
          font-weight: 900;
          padding-bottom: 50px;
        }
        .slider-p{
          margin-right: 200px;
        }
        .slider-p::before{
          content: '';
          width: 30px;
          height: 30px;
          background: url('./images/tickblue.svg');
          position: fixed;
          margin-right: -40px;
          margin-top: -3px;
          background-size: cover;
        }
        .gold-brush::before{
          content: '';
          width: 300px;
          height: 30px;
          background: url('./images/gold-brush.png');
          position: absolute;
          margin-top: 64px;
          background-size: cover;
          display: block;
          right: 200;
        }
        #home01 p{
          margin-top : 30px;
          line-height: 50px;
          direction: rtl;
          color: white;
          font-size: 22px;
          font-weight: bolder;
          margin-left: auto;
          margin-right: 200px;
          text-align: right;
          font-family: vazirmatn
        }
        .mini-slider{
          height: 200px;
          padding: 10px;
          background-color: #fafafa;
          color: #000 !important;
          border-radius: none !important
        }
        .text-white-fix{
          color: #fff
        }
        .home-slider .slider-wrapper{
          /* width: calc(80%); */
          height: 300px;
          /* color: white; */
          border-radius: 25px
          /* padding-top: 70px */
        }
      .slider-slide1-bg{
        content: '';
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left: 0;
        z-index: 1;
        background: url('./images/sbg04.png');
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        background-position: right;
        opacity: 1
      }
      .slider-slide2-bg{
        content: '';
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left: 0;
        z-index: 1;
        background-image: url('./images/sbg05.png');
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        background-position: bottom;
        opacity: 1
      }
      .slider-slide5-bg{
        content: '';
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left: 0;
        z-index: 1;
        background-image: url('./images/sbg05.png');
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        background-position: top;
        opacity: 1
      }
      .slider-slide3-bg{
        content: '';
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left: 0;
        z-index: 1;
        background-image: url('./images/sbg04.png');
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        /* filter: blur(9px); */
      }

      .slider-slide4-bg{
        content: '';
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left: 0;
        z-index: 1;
        background: url('./images/sbg05.png');
        background-size: cover;
        background-repeat: no-repeat no-repeat;
        background-position: center;
        opacity: 1
      }
      .slider-slide1{
        opacity: 1;
        width: calc(100%);
        height: 550px;
        z-index: 2;
        position: inherit;
        font-family: shabnam !important
      }
      .slider-slide4{
        opacity: 1;
        width: calc(100%);
        height: 550px;
        z-index: 2;
        position: inherit;
        font-family: shabnam !important
      }
      .slider-slide3{
        opacity: 1;
        width: calc(100%);
        height: 300px;
        z-index: 2;
        position: inherit;
        font-family: shabnam !important
      }
      .slider-slide2{
        opacity: 1;
        width: calc(100%);
        height: 550px;
        z-index: 2;
        position: inherit;
        font-family: shabnam !important
      }

      .loader-home h3{
        margin: 0;
         position: absolute;
         top: 50%;
         left: 50%;
         margin-right: -50%;
         transform: translate(-50%, -50%);
         background-color: #fff;
         background-blend-mode: hard-light;
         z-index: 12;
      }
      .loader-home{
        overflow: hidden;
        min-height: 600px;
        width: 100%;
        background-color: #ffffff;
        z-index: 11;
        top :0;
        left: 0;
        bottom: 0;
        position: fixed;
      }
        .row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;

        }
        /* .homepage p,span{
          color: black !important
        } */
        .clock{
          margin-top: 2px
        }
        #homepage{
          padding-bottom: 100px
        }
        .sidebar .sidebar-item p{
          color: white;
          font-weight: 300;
          font-size: 14px
        }
        .tether-state-light{
          background-color: #f0f0f0;
          color: black !important
        }
      .loading-spin{
        background-color: yellow !important
      }
        .mobile-nav{
          display: none;
        }
      .coin-detail-row{
        background-color:
      }

        .d-flex-xs{
          display: flex !important
        }
        .authentication .h100{
          padding: 20px 10px 20px 10px
        }
        #home02 .result{
          display: flex;
          justify-content: space-around;
          margin-top: 30px
        }
        .text-medium {
          font-size: 13px !important;
          font-weight: 300 !important
        }
        .h100{
          height: 100px;
          /* border: 1px solid black */
        }
        p{
          font-size: 16px;

        }
        .left-side{
          padding-top: 50px;
          padding-bottom: 30px
        }
        .xs-menu{
          display: none !important
        }
        .coin-list-row:hover{
      background-color: rgba(17,153,255,.25)
        }
        .coin-list-row .coin-name{
          font-size: 14px !important;
          font-weight: 200 !important;
          font-weight: bold;
        }
        .coin-list-row .list-coin-slug{
          font-size: 12px !important;
          font-weight: 200 !important;
          margin-top: 35px;
          color: #000;
          position: absolute;
          width: 100px;
          height: 20px
        }
        .coin-list-row .small-icon{
          font-size: 12px !important;
          font-weight: 200 !important;
          margin-top: 10px;
          margin-right: 20px;
          position: absolute;
        }

        .coin-list-row span{
          font-size: 13px !important;
          font-weight: 400 !important
        }

        .coin-list-row .coin-name{
          margin-right: 3px
        }
        .svg-container{
          height: 100px
        }
        .coin-list-row{
          display: flex;
          justify-content: space-between;
          padding: 5px 0 0 15px;
          width: 100% !important;
          height: 55px;
          margin-bottom: 8px;
          overflow: hidden;
          color: #edf4f8;
          background-color: rgba(17,153,255,.1);
          border-radius: 2px;
          cursor: pointer;
          transition: background .15s;
        }

        a:hover{
          color: inherit !important;
          text-decoration: none !important
        }
      .sample-img{
        margin-top: 20px;
        width: calc(50%);
        margin-right: auto;
        display: block;
      }
        .tether-state-dark .teth-p{
          color : white;
            }
            .tether-state-dark{
              /* width: 85%; */
              vertical-align: middle;
              margin: auto;
              direction: rtl;
              background-color: rgba(17,153,255,.1);
              padding:0px 10px 0 0 ;
              font-family: vazirmatn
            }

            .tether-state-dark .teth-p11{
              display: block;
              color: white !important;
              margin: auto;
              margin-top: -10px !important
            }
        .tether-state-light .ust-light-p{
          margin-top: 6px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .tether-state-light .teth-p{
          color: black !important;
          padding-right: 10px;
          border-radius: 5px;
          width: 100%;
        }
        #home04 img:hover{
          scale : 1.05;
          transition: scale 0.6s
        }
        #home04 img{
          margin: 10px 0 20px 0;
          scale : 1
        }
        body.dark{
          #home04 p::after{
            background-color: #ffc107;
            content: "";
            display: inline-grid;
            height: 10px;
            margin-left: 10px;
            rotate : 45deg;
            width: 10px;

          }
        }
        #home04 p::after{
          background-color: #ffc107;
          content: "";
          display: inline-grid;
          height: 10px;
          margin-left: 10px;
          rotate : 45deg;
          width: 10px;

        }
        #home04 p{
          font-family: vazirmatn;
          line-height: 2rem
        }
        #home04{

          /* border :1px solid white; */
          padding-top: 100px;
          margin-top: 50px;

        }
        .crypto-list-container{
          height: 600px;
          overflow-y: scroll;
          overflow-x: hidden;
        }
        .px-10{
          padding: 5px 80px 5px 80px
        }
        .tether-state{
          max-height: 65px;
          border-radius: 25px;
          display: block;
        }

        #home02{
          min-height: 150px;
          max-height: max-content;
        }
        .landing-nav {
          border-bottom: 3px solid #466bff00 !important;
          border-bottom-width: 0px;

          padding: 15px 15px 10px 15px;
          margin-top: auto;
          margin-bottom: auto;
        }
        .main-buttun{
          width: 400px
        }
        .landing-nav:hover{
          border-bottom: 3px solid #466bff !important;
          border-bottom-width: max-content;
          transition: border-bottom 0.5s ;
        }
        .home-wrapper{
          padding-top: 0px;
        }
      .ticker-list-deposit{
        /* width: 600px; */
        height: 80px;
        margin-left: auto;
      }

      #home01{

      }
      #home02{
        margin-top: 100px
      }
      #home03{
        text-align: right;
        direction: rtl;
        font-family: Shabnam;
        color: white;
        margin-top: 100px
      }
      .calc-from{
        margin: 0 50px 0 50px;
      }
      .coin-list p{
        padding: 30px 0 0px 0;
        text-align: right;
        direction: rtl;

      }
      .coin-list-2{
        width: 100px;
        text-align: right;
        direction: rtl;
        /* margin-top: 15px !important; */
        color: black
      }
      .coin-list-3{
        width: 70px;
      }
      .coin-list:hover{
        background-color: #ffffff25;
      }
      .coin-list-first{
        display: flex;
        justify-content: space-between;
        color: #288888;
        padding: 5px 10px 0 15px;
      }
      .coin-list{
        /* height:  65px; */
        border-radius: 5px;
        width: 100%;
        background-color: #ffffff10;
        color : white;
        text-align: center;
        padding: 0 0px 0 20px;
        margin: 10px 0 5px 0 ;
      }
      .coin-list-light{
        /* height:  65px; */
        border-radius: 5px;
        width: 100%;
        background-color: #ffffff;
        color : #000;
        text-align: center;
        padding: 10px 0px 0 0px;
        margin: 10px 0 5px 0 ;
      }
      .coin-list-light p{

        padding: 0px 0px 5px 0px;
        margin: 5px 0 5px 0 ;
      }


        .card-01{
          min-height: 201px
        }
        .ticker-list{
          width: calc(70%)
        }
        .has-border-withdrawal{
          /* border: 1px solid #1a1a1a20;
          background-color: #75cdad50; */
          color: black;
          margin: 50px 0 0 0;
        }
        .logo-container-xs-2{
          display: none !important
        }

        .fiat-span{
          font-weight: bolder;
          margin-right: auto;
        }
        .main-wrapper .usdt-container{
          position: sticky;
          top :60px !important
        }
        .input-span{
          position: absolute;
          margin-top: -37px;
          right: 51px;
      }
      .input-custome-button{
        border: 1px solid #ced4da !important;
        border-left-width:  2px !important;


      }

      .input-group input{
        text-align: center;
        font-size: 18px;
        height: 43px
        /* border-right : none; */
      }
      .input-group .btn{
        text-align: center;
        font-size: 18px;
        height: 43px
        /* border-right : none; */
      }
      .btn{
        height: 43px
      }
      .swap-input{
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding-left: 40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: none !important;
      }
      .dark-dropdownitem:hover{
        color: #fff !important;
        background: #000518 !important
      }
      .input-group span{
        border-left: none;
        /* border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important; */
        /* border-right : none; */
      }
      .input-group .icon{
      border-radius: 50% !important
        /* border-right : none; */
      }
      .swap-input:focus-visible{
        box-shadow: none !important;
        outline: none !important
      }
      input.swap-input{
        color: #000;
        border-right: none !important;
        text-align: center;
        border-left: 1px solid #ced4da !important;
        border-top: 1px solid #ced4da !important;
        border-bottom: 1px solid #ced4da !important;
      }
        .main-wrapper .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: 0px !important;
        margin-left:  0px !important
      }
        .main-wrapper{
          margin-right: 30px;
          padding-right: 250px;
          padding-top: 70px;
          padding-left: 0;
          margin-left: 50px;
        }
        .withdrawal:not(.show) {
          height: 0px !important;
          border-top: #ffffff20
      }
      /* #auth-login .form-control:focus {
          color: #212529;
          background-color: #17314a;

          outline: 0;
          box-shadow: none;
          color: white
      } */
      #calc .calc-magnifier{
        position: relative;
        float: right;
        margin-top: -35px;
        margin-right: 20px;
        border: none !important
      }
      #exchange {

      }
      #exchange p{
        font-family: vazirmatn !important;
        float: right;
        text-align: right;
        margin-right: 60px;
      }
      .c-i{
        direction: rtl !important;
        text-align: right !important;
        display: block;

      }
      #exchange .c-i::after{
        background-color: #5fabff50;
        content: "";
        display: inline-grid;
        height: 15px;
        margin-left: 15px;
        rotate : 45deg;
        width: 15px;

      }
      .cr-list-button{
        justify-content: space-between;
        display: flex;
        font-size: 12px !important
      }
      .brushed{
        background:url('./images/brush.svg');
        content: '';
        margin: auto;
        padding: 12px;
        width: 120px !important;
        height: 50px;
        background-size: cover;
        background-repeat: no-repeat;
        /* position: absolute; */
        /* margin-bottom: -66px; */
        /* margin-top: 20px; */

      }
      #usdt{
        border:1px solid #6059595e !important;
        align-items: center;
        /* padding: 10px; */
        border-radius: 5px
      }
      .btn.r0{
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      #exchange .ltr{
        text-align: left;
        direction: ltr;
        float: left;
        margin-right: auto;
      }
      #exchage .btn-dark , .drop-down{
      height: 60px
      }
      #exchange .coin-row:hover{
        background-color: #17314a50
      }
      .exchange-icon{
        /* margin-top: 60px  */
      }
      .dropdown-list-row{
        max-height: 200px;
        margin-top: 10px;
      }
      .market .col-xl-6{
        padding-left: 1px;
        /* padding-right: 1px */
      }
      .market-input-symbol{
        cursor: pointer;
        position: absolute;
        margin-top: -25px;
        left: 10px;
        font-size: 14px;
        /* width: 22px; */
        height: 17px;
        text-align: left;
        direction: ltr;
        color: #00000070;
        display: block;
        margin-right: auto;
        font-size: 9px;
        background-color: #fff;
        padding: 3px 5px 0 5px;
      }
      .header-sec-01{
        display: flex
      }
      .serach-magnifier {
          position: fixed;
          display: block;
          top: 15px;
          right: 4px;
          margin-left: auto;
          padding: 6px 0px 5px 8px;
          background-color: #081228;
          border-radius: 20px;
          width: 100px;
          color: white
      }
      .card-row-left{
        height: 187px;
        width: 350px;
        padding: 100px 0 50px 0;
        margin: 50px;
        padding-top: 40px;
        border-radius: 10px;

      }
      .sticky-top-container{
        position: sticky;
        top: 60px;
        z-index: 5;
        background-color: #fff;
      }
      /* .top-navigation{
        position: fixed;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 70px;
        display: block;
      } */
      .horizental-menu{
        /* display: none !important; */
      }
      .landing-navbar{
        height: 60px !important
      }
      .card-row-right{
        height: 187px;
        width: 350px;
        padding: 100px 0 50px 0;
        margin: 50px;
        padding-top: 40px;
        border-radius: 10px;

      }
      .card-row-center{
        height: 187px;
        width: 350px;
        padding: 100px 0 50px 0;
        margin: 50px;
        padding-top: 40px;
        border-radius: 10px;
        z-index: 2
      }
      .card-row-glass{
        background-color: #ffffff50;
        backdrop-filter: blur(5px);
        height: 187px;
        width: 350px;
        padding: 100px 0 50px 0;
        top: 0;
        padding-top: 40px;
        border-radius: 10px;
        z-index: -1;
        position: absolute;
      }
      #home04{
        background-image: url('./images/04bg.jpg');
        background-size: cover;
        height: 400px;
        padding-top: 100px !important;
        width: calc(100%) !important;
        background-repeat: no-repeat;
        background-position: center;
      }
      .market .col-xl-5{
        padding-left: 1px;
        /* padding-right: 10px */
      }

      .h-1000{
        height: 1000px
      }
      .order-list{
        padding-left: 1px !important;
        padding-right: 1px !important;
        margin-left: 0px;
        margin-right: 0px;
      }

      .orderlist-container{
        max-height: 300px;
        min-height: 300.5px;
        overflow-y: scroll;
      }
      #exchange .coin-row{
        height: 60px;
        background-color: #17314a;

      }
      .list-header{
        background-color: #cae8ff;
        padding: 15px 10px 15px 20px;
        margin-bottom: 5px;
      }
      #exchange{
        /* height: 500px; */
        padding-top: 100px;
        margin-top: 100px
      }
      #usdt-dark{
        position: sticky;
        top : 80px;
        z-index: 5;
        background-color: #021430
      }
      #calc .form-control:focus {
          color: #212529;
          background-color: #ffffff00;
          border-top: none;
          border-left: none;
          border-right: none;
          outline: 0;
          box-shadow: none;
          text-align: center;
          direction: ltr;
      }
      /* #auth-login input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;

      } */
      #calc input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;

      }
      /* #auth-login input:focus-visible {

          outline: none !important;
          font-size: 1.2rem
      } */
      #calc input:focus-visible {
          box-shadow: none;
          border-top: none;
          border-left : none;
          border-right: none;
          outline: none !important;
          font-size: 1.2rem
      }
      /* #auth-login .form-control,.btn{
        margin: 10px 0 10px 0;
      } */
      .redirect p{
        margin-top: 30px;
        color:#00a5ca !important
      }
      .family-input{
        width: 65%;
        margin-right: 5px
      }
      input{
        /* height: 60px; */
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        font-size: 1.2rem;
      }
      #auth-login input{
        /* height: 60px; */
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        font-size: 1.2rem;
      }
      #calc input{
        border-top: none;
        border-left : none;
        border-right: none;
        border-bottom: 2px solid #00a5ca;
        text-align: center;
        font-size: 25px;
        background-color: #ffffff00 !important;
        color: #000;
        font-size: 1.2rem;
      }
      .hero-icon-4::before{
        content: '';
        background: url('./images/zap-2.svg');
        width: 155px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 40px;
        margin-right: -33px;
      }
      #merchant::before{
        content: '';
        background: url('./images/lines-hero.svg');
        background-size:cover;
        width: 300px;
        height:calc(100% + 50px);
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        margin-top: -58px;
        /* margin-left: -74px; */
      }
      #merchant::after{
        content: '';
        background: url('./images/coding-icon.svg');
        background-size:cover;
        width: 40px;
        height:30px;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        margin-top: 100px;
        box-shadow: 1px 1px 30px 10px #888888;
        /* margin-top: -58px; */
        /* margin-left: -74px; */
      }
      .tether-state-dark .usdt-icon{
        margin-top: -3px;
        size : 50px
      }

      #form::after{
        content: '';
        background: url('./images/coding-icon-dark.svg');
        background-size:cover;
        width: 40px;
        height:30px;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        /* margin-top: -100px; */
        box-shadow: 1px 1px 30px 10px #888888;
        /* margin-top: -58px; */
        /* margin-left: -74px; */
      }
      #form::before{
        content: '';
        background-image: linear-gradient(green,white,indigo);
        width: 3px;
        height: calc(100% - 80px);
        position: absolute;
        z-index: 3;
        margin-top: -40px;
        margin-left: 19px;
      }

      .historylist-container small{
        max-width: 12ch !important
      }
      .historylist-container{
        height: 670px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .favoritelist-container{
        height: calc(30%);
        overflow: auto;
        overflow-x: hidden;
        padding: 14px 10px;
        overflow-y: scroll;
      }
      .accountlist-container{
        height: calc(70%);
        overflow: auto;
        overflow-x: hidden;
        padding: 14px 10px;
        overflow-y: scroll;
      }
      .hero-icon::before{
        content: '';
        background: url('./images/zap.svg');
        width: 374px;
        height: 50px;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 53px;
      }
      .font-roboto{
        font-weight: bolder
      }
      .hero-icon-2::before{
        content: '';
        background: url('./images/zap.svg');
        width: calc(100%);
        height: 50px;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 90px;
      }
      .login-button{
        padding: 5px 10px 5px 10px;
      }
      .navbar-expand .navbar-collapse {
          margin-left: 50px;
          font-size: 18px;
          font-family: Shabnam
      }
      .hero-icon-3::before{
        content: '';
        background: url('./images/zap-2.svg');
        width: 65px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        margin-bottom: -66px;
        margin-top: 30px;
      }
      .withdrawal{
        background-color: white;
        color : black;
        width: 100%;
        height: 350px;
        margin: auto;
        position:fixed;
        bottom: 0;
        z-index: 5;
        transition: height 0.5s ease-in-out;
        transition: border-top 0.8 ease-in-out;
        border-top: 2px solid #000222;
        -webkit-clip-path: polygon(54% 33%, 100% 50%, 100% 100%, 0 100%, 0 50%);
      clip-path: polygon(54% 33%, 100% 50%, 100% 100%, 0 100%, 0 50%);
      }
        .gateway-wrapper .card-body{
          /* color: white !important; */
          background-color: #ffffff;
          color : #000;
        }
        .crypto-list{
          position: absolute !important;
          width: 500px;
          height: 200px;
          overflow-y:scroll;
        }
        .card-gradiant-blue{
          margin-top: 50px;
          margin-inline : 20px
        }
        .card-gateway{
          display: flex !important;
            justify-content: space-between !important;

          width: calc(100%) !important
        }
        .side-item{
          display: flex;
          height: 35px
        }
        .side-item span{
          margin-top: -6px !important;
          margin-left: 10px !important;
          cursor: pointer;
        }
        .btn-step{
          background-color: #000222;
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 10px;
          padding-bottom: 10px;
          outline: 1px solid #000222;
          border-radius: 20px;
          width: 200px;
        }
        .btn-step:hover{
          background-color: #01054e;
        }
        .btn-step:active{
          background-color: #01054e;
        }
        .btn-step:focus{
          background-color: #01054e;
        }
        .step2-voucher{
          width: 100px;
          height: 100px;
          background: url('./images/voucher.svg');
          background-size: contain;
          display: inline-grid;
          position: relative;
          cursor: pointer;
        }
        .step2-crypto{
          width: 100px;
          height: 100px;
          background: url('./images/cryptocurrency.svg');
          background-size: contain;
          display: inline-grid;
          position: relative;
          cursor: pointer;
        }
        .step1-gateway{
          width: 100px;
          height: 100px;
          background: url('./images/gateway.svg');
          background-size: contain;
          display: inline-grid;
          position: relative;
          cursor: pointer;
        }
        .step1-form{
          width: 100px;
          height: 100px;
          background: url('./images/form.svg');
          background-size: contain;
          display: inline-grid;
          cursor: pointer;
        }
        .panel-wp {
          min-height: 800px;
        }
        .card-light-left-section{
          margin-left: 20px;
          margin-right: 20px
        }
        .drop-down-gateway{
          z-index: 4 !important
        }
      .otp-container{
        display: flex;
        justify-content: space-between;
      }
      #otp input{
        font-size: 1.5rem;
        text-align: center;
      }
      #otp input:focus{
        border: none !important;
        box-shadow: none !important;
        text-align: center;

      }
      /* .TVChartContainer table{
        background-color: #00000000 !important
      }
      .TVChartContainer div{
        background-color: #00000000 !important
      } */
      .TVChartContainer{
        height: 350px !important
      }
      #otp input:active{
        text-align: center;
      }
      #otp .input-group-text{
        background-color: #139f00;
        color: white;
        border: 1px solid #139f0050
      }
      .btn-success{
        background-color: #139f00;
        color: white;
        border: 1px solid #139f0050
      }
      .otp-container{
        width: calc(75%);
        margin: auto;
        text-align: center;
      }

        .ht {
          min-height: calc(100% - 300px)
        }

        .sidebar-item :hover {
          scale: 1.01
        }

         a:hover{
          text-decoration: none!important
        }
        .sidebar-item {
          padding-left : 15px;
          padding-right : 15px;
          border-bottom : 1px solid #00020005;
          border-bottom-width:thin;
          border-radius: 3px;
          height :50px !important;
          padding-top : 9px;
          display: flex;
          flex: auto;
          margin: 5px 0 0 5px;
          vertical-align: middle;
        }
      .sidebar-item-active .sidebar-icon{
        color: #58e2c7 !important;

        margin-top: auto !important;
        margin-bottom: auto !important;
      }
      .sidebar-item .sidebar-icon{
        color: #fff !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        /* font-size: 18px !important */
      }
      .sidebar-item-active{
        padding-left : 15px;
        padding-right : 15px;
        border-bottom : 1px solid #00020005;
        border-bottom-width:thin;
        border-radius: 3px;
        border-left: 3px solid #7e96bc;
        height :50px !important;
        color : #fff !important;
        padding-top : 9px;
        display: flex;
        flex: auto;
        background-color: #19253eab;
        /* margin: 10px; */
        vertical-align: middle;
      }
        .interior-menu-width p {
          margin-top: 5px;
          margin-bottom: 0;
          margin-left: 15px;
          font-weight: 100
        }

        .interior-menu-width {
          width: 200px !important;
          transition: width 0.5s;
          min-height: 800px;
          max-height: calc(100%);
          background-color: #181818;
          padding: 10px 20px 10px 20px;
          border: 1px solid #fafafa10;
          border-radius: 0px;
          position: fixed;
          z-index: 3;
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          margin-top: 50px;
          font-size: 1rem
        }

        #arrow-menu-right .icon-arrow-right, .icon-arrow-left {
          content: '';
          width: 5px;
          height: 5px;
          background-color: #ffffff00;
          margin-top: 5px;
          font-weight: bold;
          margin-left: 4px;
          font-weight: 100;
        }
        .mt-10{
          margin-top: 100px
        }
        #arrow-menu-right {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: white;
          position: absolute;
          margin-top: -30px;
          right: -11px;
          box-shadow: 0px 0px 20px 1px gray;
          cursor: pointer;
        }

        .rotate-180 {
          transform: rotate(180deg);
        }

        .topbar-menu {
          width: calc(100%);
          height: 60px;
          padding-top: 5px;
          padding-bottom: 5px;
          position: fixed;
          top: 0px;
          right: 0px;
          background-color: #ecf4fa;
          left: 350px;
          color: black;
          font-weight: 100;
          direction: ltr;
        }

        .icon-shadow {
          shadow: 0px 0px 17px white
        }

        .menu-item-containers {
          margin-top: 100px
        }

        .interior-menu {
          min-height: 800px;
          max-height: calc(100%);
          width: 60px;
          background-color: #181818;
          padding: 10px 20px 10px 20px;
          border: 1px solid #fafafa10;
          border-radius: 0px;
          position: fixed;
          z-index: 3;
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          margin-top: 50px;
          box-shadow: inset -5px 5px 20px black;
          transition: width 0.5s
        }


        .fixed-top {
          position: fixed;
          top: 0;
          left: 0;
          right: 0
        }

        .pointer {
          cursor: pointer;
        }

        .panel-container-left {
          margin-left: 20px;
          margin-top: 0px;
          background-color: #ecf0fa;
          min-width: 280px;
          min-height: 600px;
          z-index: 2;
          position: fixed;
          overflow:hidden;
        }

        .panel-container-right {
          /* background-color: #e1eef7; */
          min-width: calc(100% - 310px);
          min-height: 600px;
          max-height: calc(100%);
          z-index: 1;
          margin-left: 310px;
          margin-top: 50px;
          padding: 10px 30px 0 0;
          /* right: 0; */

          padding-bottom: 100px
        }
        .content .card a{
          text-decoration: none;
        }
        .content .card{
          background-color: #ffffff00 !important;
          color: black;
          padding-left: 20px;
          padding-right: 20px
        }
        .text-small{
          font-size: 12px
        }
        .panel-container p{
          text-align: right;
          direction: rtl;
        }
        .panel-container {
          display: flex;
          min-width: calc(100%);
          min-height: 800px
        }

        .content {
          width: auto;
          background-color: #ffffff00;
          /* width: calc(100% - 60px); */
          z-index: 4;
          min-height: 500px;
          /* margin-left: 30px; */
          bottom: 0;

        }

        .content-section-1 {
          width: 75%;
          margin: auto;
        }

        .img-section-sized {
          width: calc(100%)
        }

        .justify {
          justify-content: space-between;
        }


        .exchange-section{
          border: 1px solid #c4c2c2;
          border-radius: 20px;
          margin: 50px 20px;
          padding: 50px 20px;
          box-shadow: 5px 5px 12px #fafafa50
        }
        #merchant li {
          line-height: 3rem
        }

        #merchant .btn {
          width: 100% !important;
          margin: auto;
          margin-top: 50px
        }

        #installation .btn {
          width: 50% !important;
          margin: auto;
          margin-top: 50px
        }

        #form li {
          line-height: 3rem
        }

        #form .btn {
          width: 50% !important;
          margin: auto;
          margin-top: 50px
        }
      #merchant{
        padding-bottom: 100px
      }
        #Wallet{
          padding-top: 100px;
          padding-bottom: 100px
        }
        #Wallet li {
          line-height: 3rem
        }

        #Wallet .btn {
          width: 100% !important;
          margin: auto;
          margin-top: 50px
        }

        .lh-xl {
          line-height: 3rem
        }

        .title-m {
          font-size: 36px;
          font-weight: 200;
          font-style: oblique;
        }


        .section1-xl {
          display: block !important
        }

      #hero-home-3 .card-text{
        padding: 25px;
        min-height: 100px;
        border: 1px solid #fafafa20;
        color: white;
        border-radius: 25px;
        text-align : center;
        background-color: #1a1a1a
      }
        .section1-lg .section1-md .section1-sm .section1-xs {
          display: none !important
        }

        #hero .hero-text {
          margin-top: 5rem
        }



        .bg-gray {
          background-color: #fafafa;
        }

        .h-400 {
          min-height: 400px;
        }


        .bg-shape a{
            text-decoration : none;
        }
        .bg-shape{
          background-color : #fff !important;
          min-height : 50px;
          border-bottom: 1px solid #ffffff30
        }
        .main-dashboard{
          min-height : 700px;}
        .right-dashboard{
          min-height : 700px;
          padding-top : 60px;
          background-color: white;
          position : fixed;
          right : 0
        }
        .left-dashboard{
          min-height : 700px;
          position : fixed;
          padding-top : 56px;
          display :
        }

        .left-dahboard .sidebar-item{

        }
        .jumbtron{
          padding: 6rem;
          padding-bottom: 1rem;
        }
        .main-auth .logo-container-home{
          background: url('./images/logo-dark.png');
          background-repeat: no-repeat no-repeat;
          background-size: contain;
          width: 250px;
          height: 50px;
          margin-top: 8px;
          /* position:absolute; */
          display: block;
          vertical-align: center;
          /* margin-left: auto;
          align-items: center;
          vertical-align: baseline; */
        }

      .btn-grad {
         background-image: linear-gradient(to right, #6441A5 0%, #2a0845  51%, #6441A5  100%);
         margin: 10px;
         padding: 15px 45px;
         text-align: center;
         text-transform: uppercase;
         transition: 0.5s;
         background-size: 200% auto;
         color: white;
         box-shadow: 0 0 2px #eee;
         border-radius: 10px;
         display: block;
       }

       .btn-grad:hover {
         background-position: right center; /* change the direction of the change here */
         color: #fff;
         text-decoration: none;
       }
      .xl-navbar.bg-transparent{
        color: #fff;
        background-color: #000518 !important

      }
      .xl-navbar .landing-nav{
        color: #fff;

      }
      .xl-navbar:not(.bg-transparent) a{
        color: #ffffff !important
      }
      .dark-icon-round{
        background: #162b46 !important;
        /* margin: 7px; */
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: auto 3px auto 3px;
        align-items: center;
        padding: 7px;
        display: table;
      }
      .xl-navbar.bg-transparent .dark-icon-round{
        background: #162b46 !important;
        color: #000 !important;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: auto 3px auto 3px;
        align-items: center;
        padding: 7px;
        display: table;
      }
        .logo-container-home{
          background: url('./images/logo.png');
          background-repeat: no-repeat no-repeat;
          background-size: contain;
          width: 230px;
          height: 50px;
          margin-top: 8px;
          /* position:absolute; */
          display: block;
          vertical-align: center;
          /* margin-left: auto;
          align-items: center;
          vertical-align: baseline; */
        }
        .panel-navbar{
          padding-bottom: 10px
        }
        .navbar-menu-lg{
          display: none
        }
      .panel-container-left .card{
        background: #000
      }
        #supported-coins .card {
          border-color: #fafafa50 !important
        }
      .justify-content-space-between{
        justify-content: space-between;
      }
        #root {

        }

        #hero .input-group-text {
          background-color: #139f00;
          border-color: #139f00;
          color: white
        }

        .login-btn-navbar a {
          text-decoration: none !important;
        }

        .login-btn-navbar {
          margin-left: auto !important;
          padding: 5px 30px 5px 30px;
          background-color: #ffffff00;
          color: white;
          border: 1px solid #fafafa;
          border-radius: 5px
        }

    .xs-md-coin-name{
      margin-right: 10px;
      font-size: 17px !important;
      margin-top: 10px
    }
    .text-dark{
      color: #000
    }
    .scrollHider .menuBox{
      height: 50px;
      background-color: #000518;
      padding: 15px 30px 0 0;
      width: 100%
    }
  .menuBox a{
      min-width: 100px;
      color: #fff;
      margin-left: 30px;
      display: inline-block;
      text-align: center;
    }
  a.active-tab{
      font-size: 14px;
      font-weight: bold;
    }
    #a2{
      color: #fff !important;
      background-color: #162b46 !important;
      border: 1px solid #000518 !important;
      margin-right: auto;
    }
  .scrollHider
    {
    display: flex !important;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    align-items: center;
    position: relative;

    width: 100%
    }
}
#dashboard-chart-section{
  border-radius: 10px;
  width: 350px;
  margin-top: 50px
}
.radius-xl{
  border-radius: 10px
}
