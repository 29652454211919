.yfdai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tiaup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ordi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ssv-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.auction-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.btcup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.injup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tia-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dcr-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.methup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.seidown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ordiup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.strike-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kace-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.updown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.uqc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lyx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.opup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cyber-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gnc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.flip-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vraup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.seiup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.orca-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.seam-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bakeup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dydxup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pendle-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.manta-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pythup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kasdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wld-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gtai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.infra-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arb3l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kasup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sols-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arb-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.jto-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.iron-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sui-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ethdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lqty-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zeta-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.neon-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.btcdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mina-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tomi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.luncup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ntrn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wldup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pyusd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.waxl-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arty-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.aa-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.fire-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.strax-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.unfidown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.syn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ator-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.combo-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mav-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.edu-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sui3l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.unfiup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sei-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mnt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.blur-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.trbdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.elf-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arkm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hifi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.fet-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.jup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vradown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.nfp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cfx2l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.id-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dmail-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pyth-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lbr-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.work-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1inch-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lmwr-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.oofp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.defi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pythdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kalt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zrx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bigtime-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.blz-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rdnt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.alex-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dovi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pzp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.finc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ofn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vrtx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ondo-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mnde-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.nom-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.shrap-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wif-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.trbup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bmx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cgpt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dydxdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.insp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.suip-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.polyx-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bakedown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.suia-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cetus-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.aipad-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.nxra-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kas-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mubi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.atem-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hmnd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.id3l-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pmg-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xpll-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tune-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.islm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.cfx2s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tenet-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.loom-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.afg-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.port3-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.utk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.root-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pip-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tsugt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mxm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.csix-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.otk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.irl-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.goal-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.myro-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vanry-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kagi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dck-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.arb3s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.silly-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sui3s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xrd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bidp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.id3s-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sns-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ego-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.token-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepedown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.app-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.igu-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.grape-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.amu-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.locus-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.meme-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.halo-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hon-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rpk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.luncdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.candy-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gft-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.scpt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wldown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.turt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.izi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.lai-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.biis-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.qkc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zkf-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.obi-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.gtt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.myria-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepeup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hibakc-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.tiadown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zpay-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.kpol-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.karate-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.saros-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.klup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sidus-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1cat-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.aiepk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wlkn-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zooa-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.turbos-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.roup-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rndrdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.olt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.acs-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mobile-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mind-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.injdown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.vcore-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hiseals-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.1earth-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mmm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.dent-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hibeanz-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ordidown-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.hifriends-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.zelix-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.verse-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.issp-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rats-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.mm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.analos-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.wen-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.etgm-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.floki-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bob-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.bonk-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.rfd-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.coq-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepe-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.sats-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.xen-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.volt-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.ladys-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.pepe2-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
.babydoge-icon{
  background: url('./crypto/ada.png');
  background-repeat: no-repeat no-repeat ;
  background-position: center ;
  background-size: cover ;
  width: 32px ;
  height: 32px  ;
  float: right ;
  content: inherit ; background-color : white; border-radius : 50%

}
